import React from 'react';
import { Link } from 'react-router-dom';

import { urls, footerLinks } from '../../utils/routing';

//import SocialLinks from './SocialLinks';

import styles from './Footer.module.css';
import './FooterLinks.css';

export default function Footer() {
  return (
    <footer className={styles.base}>
        <div className={styles.block}>
            <div style={{paddingRight: "40px"}}>
                <a className={styles.logo} href="/">
                    <span style={{ color: "rgb(206,212,224)"}}>TANKIMODS.NET</span>
                </a>
                {/* <a href="/" className="Footer_logo" /> */}
                <div className={styles.copy}>
                    <span>© {(new Date().getFullYear())} Все права защищены. 12+</span>
                </div>
            </div>
                <Link
                    key={ footerLinks.eula.href }
                    to={ urls.eula }
                    className={ footerLinks.eula.isExternal ? 'FooterLinks_link FooterLinks_isExternal' : 'FooterLinks_link' }
                    target={ footerLinks.eula.isExternal ? '_blank' : '_top' }
                >{ footerLinks.eula.title }</Link>
                <Link
                    key={ footerLinks.termsofuse.href }
                    to={ urls.termsofuse }
                    className={ footerLinks.termsofuse.isExternal ? 'FooterLinks_link FooterLinks_isExternal' : 'FooterLinks_link' }
                    target={ footerLinks.termsofuse.isExternal ? '_blank' : '_top' }
                >{ footerLinks.termsofuse.title }</Link>
                <Link
                    key={ footerLinks.problemInfo.href }
                    to={ urls.problemInfo }
                    className={ footerLinks.problemInfo.isExternal ? 'FooterLinks_link FooterLinks_isExternal' : 'FooterLinks_link' }
                    target={ footerLinks.problemInfo.isExternal ? '_blank' : '_top' }
                >{ footerLinks.problemInfo.title }</Link>
                <Link
                    key={ footerLinks.tanki.href }
                    to={ urls.tanki }
                    className={ footerLinks.tanki.isExternal ? 'FooterLinks_link FooterLinks_isExternal' : 'FooterLinks_link' }
                    target={ footerLinks.tanki.isExternal ? '_blank' : '_top' }
                >{ footerLinks.tanki.title }</Link>
            <div className={styles.social_links}>
                {/* <SocialLinks /> */}
                {/*<div className={styles.social_links__item}>
                    <a href="https://vk.com/just_sherlock" target="_blank" rel="noreferrer">
                        <svg className="social-links__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" xmlSpace="preserve">
                            <desc>VK</desc>
                            <path style={{ fill: "rgb(206,212,224,1)" }} d="M47.761,24c0,13.121-10.639,23.76-23.76,23.76C10.878,47.76,0.239,37.121,0.239,24c0-13.123,10.639-23.76,23.762-23.76C37.122,0.24,47.761,10.877,47.761,24 M35.259,28.999c-2.621-2.433-2.271-2.041,0.89-6.25c1.923-2.562,2.696-4.126,2.45-4.796c-0.227-0.639-1.64-0.469-1.64-0.469l-4.71,0.029c0,0-0.351-0.048-0.609,0.106c-0.249,0.151-0.414,0.505-0.414,0.505s-0.742,1.982-1.734,3.669c-2.094,3.559-2.935,3.747-3.277,3.524c-0.796-0.516-0.597-2.068-0.597-3.171c0-3.449,0.522-4.887-1.02-5.259c-0.511-0.124-0.887-0.205-2.195-0.219c-1.678-0.016-3.101,0.007-3.904,0.398c-0.536,0.263-0.949,0.847-0.697,0.88c0.31,0.041,1.016,0.192,1.388,0.699c0.484,0.656,0.464,2.131,0.464,2.131s0.282,4.056-0.646,4.561c-0.632,0.347-1.503-0.36-3.37-3.588c-0.958-1.652-1.68-3.481-1.68-3.481s-0.14-0.344-0.392-0.527c-0.299-0.222-0.722-0.298-0.722-0.298l-4.469,0.018c0,0-0.674-0.003-0.919,0.289c-0.219,0.259-0.018,0.752-0.018,0.752s3.499,8.104,7.573,12.23c3.638,3.784,7.764,3.36,7.764,3.36h1.867c0,0,0.566,0.113,0.854-0.189c0.265-0.288,0.256-0.646,0.256-0.646s-0.034-2.512,1.129-2.883c1.15-0.36,2.624,2.429,4.188,3.497c1.182,0.812,2.079,0.633,2.079,0.633l4.181-0.056c0,0,2.186-0.136,1.149-1.858C38.281,32.451,37.763,31.321,35.259,28.999"></path>
                        </svg>
                    </a>
                </div>
                <div className={styles.social_links__item}>
                    <a href="https://t.me/justsherlock_ru" target="_blank" rel="noreferrer">
                        <svg className="social-links__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 16 16" enableBackground="new 0 0 48 48" xmlSpace="preserve">
                            <desc>Telegram</desc>
                            <path style={{ fill: "rgb(206,212,224,1)" }} d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                        </svg>
                    </a>
                </div>
                <div className={styles.social_links__item}>
                    <a href="https://www.youtube.com/channel/UCQ_X_IWB9HhcI8k7PAt_WLA" target="_blank" rel="noreferrer">
                        <svg className="social-links__svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" xmlSpace="preserve">
                            <desc>Youtube</desc>
                            <path style={{ fill: "rgb(206,212,224,1)" }} d="M24 0.0130005C37.248 0.0130005 47.987 10.753 47.987 24C47.987 37.247 37.247 47.987 24 47.987C10.753 47.987 0.0130005 37.247 0.0130005 24C0.0130005 10.753 10.752 0.0130005 24 0.0130005ZM35.815 18.093C35.565 16.756 34.452 15.758 33.173 15.635C30.119 15.439 27.054 15.28 23.995 15.278C20.936 15.276 17.882 15.432 14.828 15.625C13.544 15.749 12.431 16.742 12.182 18.084C11.898 20.017 11.756 21.969 11.756 23.92C11.756 25.871 11.898 27.823 12.182 29.756C12.431 31.098 13.544 32.21 14.828 32.333C17.883 32.526 20.935 32.723 23.995 32.723C27.053 32.723 30.121 32.551 33.173 32.353C34.452 32.229 35.565 31.084 35.815 29.747C36.101 27.817 36.244 25.868 36.244 23.919C36.244 21.971 36.101 20.023 35.815 18.093ZM21.224 27.435V20.32L27.851 23.878L21.224 27.435Z"></path>
                        </svg>
                    </a>
                </div>*/}
            </div>
        </div>
    </footer>
  )
}
