import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { filter, includes, isEmpty } from 'lodash'; // 

import { MOD_STATE, MOD_VERSION_STATUS, SEARCH_RESULT_ITEMS_ORDERING } from '../../utils/constants';
import { urls, pushHistoryWithCreate } from '../../utils/routing';
import { checkTokenIsExp, getUsername, getUserRole } from '../../utils/functions';

import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';
import Main from '../../components/Main/Main';
import Back from '../../components/Buttons/Back/Back';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import Caption from '../../components/Caption/Caption';
import FilterSelect from '../../components/FilterSelect/FilterSelect';
import Toggle from '../Toggle/Toggle';
import Information from '../../components/Information/Information';
import NotificationBlockedUser from '../../components/NotificationBlockedUser/NotificationBlockedUser';
import ErrorBlocked from '../../components/ErrorBlocked/ErrorBlocked';
import Error from '../../components/Error/Error';
import AddMod from '../../components/AddMod/AddMod';
import CardMod from '../../components/CardMod/CardMod';

import {
    MODDER_SECTION_PAGE_TITLE_DEFAULT,
    MODDER_SECTION_PAGE_TITLE_EMPTY,
    MODDER_SECTION_LOGIN_MESSAGE,
    MODDER_SECTION_LOGIN,
    MODDER_SECTION_FILTER_CAPTION,
    MODDER_SECTION_ADD_MOD_MESSAGE,
    MODDER_SECTION_ADD_MOD,
    MODDER_SECTION_ALL_STATE_VALUES,
    messages
} from './translations';

import {
  COMMON_ERROR,
  MOD_STATE_TITLES,
  ORDERING_BY_CHANGED_AT_FILTERED_MODS,
  ORDERING_BY_RATING_FILTERED_MODS,
  TO_LANDING,
} from '../../translations';

import styles from './SectionModder.module.css';


export default class SectionModder extends React.Component {
    static propTypes = {
        isBlocked: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isError: PropTypes.bool,
        isLoggedIn: PropTypes.bool,
        isRulesAccepted: PropTypes.bool,
        isFetching: PropTypes.bool.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            cover: PropTypes.string,
            gameVersion: PropTypes.string,
            id: PropTypes.number.isRequired,
            isHidden: PropTypes.bool.isRequired,
            mark: PropTypes.number.isRequired,
            modVersion: PropTypes.string,
            ownerId: PropTypes.number.isRequired,
            ownerName: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                caption: PropTypes.string.isRequired,
            })).isRequired,
            title: PropTypes.string.isRequired,
            state: PropTypes.number.isRequired,
        })).isRequired,

        history: ReactRouterPropTypes.history, // isRequired

        //intl: intlShape,

        fetchItems: PropTypes.func.isRequired,
        onShowLoginDialog: PropTypes.func.isRequired,
        onShowRulesDialog: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isBlocked: false,
        isDisabled: false,
        isLoggedIn: true,
        isError: true,
    }

    constructor() {
        super()
        this.allowedModStates = [MOD_STATE.DEFAULT]

        this.MOD_STATE_ITEMS = [{
            value: MOD_STATE.DEFAULT,
            caption: MODDER_SECTION_ALL_STATE_VALUES,
        }, {
            value: MOD_STATE.DRAFT,
            caption: MOD_STATE_TITLES[MOD_STATE.DRAFT],
        }, {
            value: MOD_STATE.IN_REVIEW,
            caption: MOD_STATE_TITLES[MOD_STATE.IN_REVIEW],
        }, {
            value: MOD_STATE.REJECTED,
            caption: MOD_STATE_TITLES[MOD_STATE.REJECTED],
        }, {
            value: MOD_STATE.PUBLISHED,
            caption: MOD_STATE_TITLES[MOD_STATE.PUBLISHED],
        }, {
            value: MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW,
            caption: MOD_STATE_TITLES[MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW],
        }, {
            value: MOD_STATE.PUBLISHED_UPDATE_REJECTED,
            caption: MOD_STATE_TITLES[MOD_STATE.PUBLISHED_UPDATE_REJECTED],
        }, {
            value: MOD_STATE.HIDDEN,
            caption: MOD_STATE_TITLES[MOD_STATE.HIDDEN],
        }]

        this.filteredItems = []
        this.state = {
            selectedModState: MOD_STATE.DEFAULT,
            allowedModStates: [MOD_STATE.DEFAULT],
            filteredItems: [],
        }
    }

    componentDidMount() {
        if (this.props.isRulesAccepted === false) {
          this.props.onShowRulesDialog()
        }
        if (checkTokenIsExp() === true) {
            this.props.fetchItems(getUsername())
        }
        document.title = messages
    }

    UNSAFE_componentWillReceiveProps(nextProps) { // componentDidUpdate
        if (nextProps.items.length > 0) {
            const allModStates = nextProps.items.filter((item) => !item.isHidden).map((item) => item.state)
            const hasHiddenMods = !!nextProps.items.find((item) => item.isHidden)

            this.allowedModStates = [...new Set(allModStates), MOD_STATE.DEFAULT]
            if (hasHiddenMods) {
                this.allowedModStates = [...this.allowedModStates, MOD_STATE.HIDDEN]
            }
            this.filteredItems = nextProps.items
        }
    }

    handleModStateChange = (selectedModState) => {
        this.filteredItems = this.props.items
        if (selectedModState !== MOD_STATE.DEFAULT) {
          this.filteredItems = filter(this.filteredItems, (item) => {
              return selectedModState === MOD_STATE.HIDDEN ?
                  item.isHidden : selectedModState === item.state && !item.isHidden
          })
        }
        this.setState({ selectedModState })
    }

    handleAddModClick = () => {
      if ((typeof this.props.history !== "undefined") && this.props.history !== null ) {
        pushHistoryWithCreate(this.props.history)
      } else {
        window.location.href = urls.create;
      }
    }

    renderInformation() {
        return !this.props.isLoggedIn && !this.props.isError ? (
            <Information
                message={MODDER_SECTION_LOGIN_MESSAGE}
                buttonCaption={MODDER_SECTION_LOGIN}
                onButtonClick={this.props.onShowLoginDialog}
            />
        ) : null
    }

    renderNoModsInformation() {
        return !this.props.isDisabled ? (
            <Information
                message={MODDER_SECTION_ADD_MOD_MESSAGE}
                buttonCaption={MODDER_SECTION_ADD_MOD}
                onButtonClick={this.handleAddModClick}
            />
        ) : null
    }

    renderDisabledInformation() {
        return !this.props.isBlocked && this.props.isDisabled && !this.props.isError ? (
            <div className={styles.notification}>
                <NotificationBlockedUser history={this.props.history} />
            </div>
        ) : null
    }

    renderBlockedInformation() {
        return this.props.isBlocked ? (
            <ErrorBlocked history={this.props.history} />
        ) : null
    }

    renderErrorMessage() {
        return this.props.isError ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null
    }

    renderFilter() {
        if (this.props.isError || isEmpty(this.props.items)) {
            return null
        }
        const classNameFilter = classNames(styles.filter, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        const items = filter(this.MOD_STATE_ITEMS, (item) => {
            return includes(this.allowedModStates, item.value)
        })

        return this.props.isLoggedIn && !this.props.isBlocked ? (
            <div className={classNameFilter}>
                <Caption>{MODDER_SECTION_FILTER_CAPTION}</Caption>
                <div className={styles.select}>
                    <FilterSelect
                        items={items}
                        selectedValue={this.state.selectedModState}
                        onItemChange={this.handleModStateChange}
                    />
                </div>
            </div>
        ) : null
    }

    renderFilterMods() {
        if (this.props.isError || isEmpty(this.props.items)) {
            return null
        }
        const classNameFilter = classNames(styles.filter, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        return this.props.isLoggedIn && !this.props.isBlocked ? (
            <div className={classNameFilter}>
                <Toggle
                    items={[
                        {
                            value: SEARCH_RESULT_ITEMS_ORDERING.RATING,
                            caption: ORDERING_BY_RATING_FILTERED_MODS,
                        },
                        {
                            value: SEARCH_RESULT_ITEMS_ORDERING.CHANGED_AT,
                            caption: ORDERING_BY_CHANGED_AT_FILTERED_MODS,
                        },
                    ]}
                    isDisabled={this.props.filtered.items.length === 0}
                    checkedItem={this.props.filtered.filterParams.ordering}
                    onItemChange={()=>{}}
                />
            </div>
        ) : null
    }

    renderItems() {
        return this.filteredItems.map((item) => {
            const isModPublished = [
                MOD_STATE.PUBLISHED,
                MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW,
                MOD_STATE.PUBLISHED_UPDATE_REJECTED,
            ].includes(item.state)
            const isModVersionPublished = !!item.versions.find(
                (version) => version.status === MOD_VERSION_STATUS.PUBLISHED
            )
            const isViewModLinkEnabled = isModPublished && isModVersionPublished && !item.isHidden

            return (
                <div className={styles.mod} key={item.id}>
                    <CardMod
                        authorName={item.authorName}
                        cover={item.cover}
                        gameVersion={item.gameVersion}
                        id={item.id}
                        isDownloadDisabled
                        isEditLinkEnabled
                        isViewModLinkEnabled={isViewModLinkEnabled}
                        mark={item.mark}
                        modVersion={item.modVersion}
                        ownerId={item.ownerId}
                        ownerName={item.ownerName}
                        state={item.isHidden ? MOD_STATE.HIDDEN : item.state}
                        tags={item.tags}
                        title={item.title}
                        isDisabled={this.props.isDisabled}

                        history={this.props.history}
                    />
                </div>
            )
        })
    }

    renderContent() {
        if (this.props.isBlocked || this.props.isError || this.props.isFetching || !this.props.isLoggedIn) return null

        if (this.props.items.length === 0 && !this.props.isFetching) {
            return this.renderNoModsInformation()
        }
        
        return (
            <div className={styles.content}>
                <div className={styles.mod}>
                    <AddMod
                        isDisabled={this.props.isDisabled}
                        onClick={this.handleAddModClick}
                    />
                </div>
                {this.renderItems()}
            </div>
        )
    }

    renderTitle() {
        if (!this.props.isLoggedIn || this.props.isBlocked) {
          return (
              <TitleMajor>
                  {MODDER_SECTION_PAGE_TITLE_EMPTY}
              </TitleMajor>
          )
        }

        if (this.props.items.length > 0 && this.filteredItems.length === this.props.items.length) {
            // MODDER_SECTION_PAGE_TITLE_DEFAULT_ITEMS
            return (
              <TitleMajor>
                    {MODDER_SECTION_PAGE_TITLE_DEFAULT(
                        <React.Fragment>
                            <mark>{this.props.items.length}</mark>
                        </React.Fragment>
                    )}
              </TitleMajor>
          )
        }
        // этот блок ниже я убирал раньше
        if (this.filteredItems.length > 0 && this.filteredItems.length !== this.props.items.length) {
            return (
              <TitleMajor>
                    {/* {MODDER_SECTION_PAGE_TITLE_DEFAULT_FILTERED(this.filteredItems.length, this.props.items.length)} */}
                    {MODDER_SECTION_PAGE_TITLE_DEFAULT(
                        <React.Fragment>
                            <mark>{this.filteredItems.length}</mark>
                            <span className={styles.titleSeparator}>/</span>
                            {this.props.items.length}
                        </React.Fragment>
                    )}
              </TitleMajor>
          )
        }
        
        if (this.props.items.length === 0) {
            return (
                <TitleMajor>
                    {MODDER_SECTION_PAGE_TITLE_DEFAULT(
                        <span className={styles.muted}>{this.props.items.length}</span>
                    )}
                </TitleMajor>
            )
        }
    }

    render() {
      if ((!window.__URLS__.pages.modderSection) && (getUserRole() !== 'admin')) {
        return <UnavailablePage />
      } else {
      return (
          <Main>
              <div className={styles.base}>
                  <div className={styles.head}>
                      <Back caption={TO_LANDING} to={urls.landing} />
                      <div className={styles.header}>
                          {this.renderTitle()}
                          {this.renderFilter()}
                          {/* {this.renderFilterMods()} */}
                          {/* {this.renderFilter()} */}
                      </div>
                  </div>
                  <div className={styles.body}>
                      {this.renderInformation()}
                      {this.renderDisabledInformation()}
                      {this.renderContent()}
                      {this.renderBlockedInformation()}
                      {this.renderErrorMessage()}
                  </div>
              </div>
          </Main>
      )
    }
    }
}
