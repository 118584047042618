import React from 'react';
import { Route, Routes } from 'react-router-dom';
import classNames from 'classnames';

import { urls } from '../../utils/routing';
import { getUserRole } from '../../utils/functions';

import Landing from '../../containers/LandingWrapper/LandingWrapper';
import News from '../../containers/NewsWrapper/NewsWrapper';
import Followed from '../../containers/FollowedWrapper/FollowedWrapper';
import SectionModder from '../../containers/SectionModderWrapper/SectionModderWrapper';
import EditMod from '../../containers/EditModWrapper/EditModWrapper';
import CreateMod from '../../containers/CreateModWrapper/CreateModWrapper';
import Eula from '../../containers/EulaWrapper/EulaWrapper';
import TermsOfUse from '../../containers/TermsOfUseWrapper/TermsOfUseWrapper';
import Error404 from '../Error404/Error404';
import SearchPage from '../../containers/SearchPageWrapper/SearchPageWrapper';
import ModPage from '../../containers/ModPageWrapper/ModPageWrapper';

//import UserMenuWrapper from '../../containers/UserMenuWrapper/UserMenuWrapper';
import Dialog from '../../containers/DialogWrapper/DialogWrapper';
import DialogError from '../../containers/DialogErrorWrapper/DialogErrorWrapper';
import Processing from '../../containers/ProcessingWrapper/ProcessingWrapper';

import SignIn from '../../containers/SignInWrapper/SignInWrapper';
import SignUp from '../../containers/SignUpWrapper/SignUpWrapper';
import Prolongate from '../../containers/ProlongateWrapper/ProlongateWrapper';
import Logout from '../../containers/LogoutWrapper/LogoutWrapper';

import AdminWrapper from '../../containers/AdminWrapper/AdminWrapper';
import Support from '../../containers/SupportWrapper/SupportWrapper';
import ReviewMod from '../../containers/ReviewModWrapper/ReviewModWrapper';

import UnavailablePageWrapper from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';

import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
// import DialogReportMod from './components/Dialogs/DialogReportMod/DialogReportMod';

import styles from './App.module.css'; // App styles


export const ThemeContext = React.createContext('light')

export default class App extends React.Component {
  
  render() {
    if ((!window.__URLS__.pages.portal) && (getUserRole() !== 'admin')) {
      return <UnavailablePageWrapper />
    } else {
      return (
        <div className={styles.base}>
          <div className={classNames(styles.header)}>
            <Nav {...this.props} />
          </div>
          <div className={styles.body}>
            <Routes>
              <Route exact="true" path={ urls.landing } element={ <Landing {...this.props} /> } />
              {/* <Route path={urls.contentPage} component={ContentPage} /> */}
              <Route exact="true" path={`/:modId/`} element={ <ModPage {...this.props} /> } />
              <Route path={ urls.news } element={ <News {...this.props} /> } />
              <Route path={ urls.search } element={ <SearchPage {...this.props} /> } />
              <Route path={ urls.subscriptions } element={ <Followed {...this.props} /> } />
              <Route path={ urls.modderSection } element={ <SectionModder {...this.props} /> } />
              <Route path={ urls.edit } element={ <EditMod {...this.props} /> } />
              <Route path={ urls.create } element={ <CreateMod {...this.props} /> } />
              <Route path={ urls.eula } element={ <Eula {...this.props} /> } />
              <Route path={ urls.termsofuse } element={ <TermsOfUse {...this.props} /> } />
              
              <Route path={ urls.errorNotFound } element={ <Error404 {...this.props} /> } />
              <Route path="*" element={ <Error404 {...this.props} /> } />

              {/* Auth and prolong token */}
              <Route path={ urls.signin } element={ <SignIn {...this.props} /> } />
              <Route path={ urls.signup } element={ <SignUp {...this.props} /> } />
              <Route path={ urls.prolongate } element={ <Prolongate {...this.props} /> } />
              {/* Exit from account */}
              <Route path={ urls.logout } element={ <Logout {...this.props} /> } />

              {/* Admin panel */}
              <Route path={ urls.adminSection } element={ <AdminWrapper {...this.props} /> } />
              {/* Panel moderator edit mod */}
              <Route path={ urls.review } element={ <ReviewMod {...this.props} /> } />
              {/* Support panel */}
              <Route path={ urls.problemInfo } element={ <Support {...this.props} /> } />
              {/* <Route path={urls.adminSection} element={  } /> */}
            </Routes>
          </div>
          <div className={styles.footer}>
            <Footer {...this.props} />
          </div>
          <Dialog />
          <DialogError />
          <Processing />
        </div>
      )
    } // end of IF
  }
}


/*export default function App() {
  return (
      <div className={styles.base}>
        <div className={styles.header}>
          <Nav />
        </div>
        <div className={styles.body}>
          <Routes>
            <Route exact="true" path={ urls.landing } element={ <Landing available={true} /> } />
            <Route exact="true" path={`/:modId/`} element={ <ModPage available={true} {...store.modId} /> } />
            <Route path={ urls.news } element={ <News available={true} /> } />
            <Route path={ urls.subscriptions } element={ <Followed available={true} {...store.following} /> } />
            <Route path={ urls.modderSection } element={ <SectionModder available={true} {...store.modder} /> } />
            <Route path={ urls.eula } element={ <Eula available={true} /> } />
            <Route path={ urls.termsofuse } element={ <TermsOfUse available={true} /> } />

            <Route path={ urls.search } element={ <SearchPage available={true} /> } />
            
            <Route path="*" element={ <Error404 /> } />
          </Routes>
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
        <Dialog />
        <DialogError  />
      </div>
  )
}*/
