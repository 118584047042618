import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Input.module.css';

export default class Input extends React.PureComponent {
    static propTypes = {
        placeholder: PropTypes.node,
        value: PropTypes.string,
        type: PropTypes.string,
        isError: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isPassword: PropTypes.bool,
        isFocusOn: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            isPlaceholderVisible: true,
            value: '',
        }
    }

    componentDidMount() {
        if (this.props.isFocusOn) {
            this._input.focus()
        }
    }

    setFocused = (isFocused) => {
        this.setState({
            isPlaceholderVisible: !isFocused && !this.props.value,
        })
    }

    handleChange = (event) => {
        // check input write "read only"
        if (this.props.type && this.props.type === "number") {
            let value = event.target.value
            value = value.replace(/[^0-9]/g, '');

            if (value.length && (value.length >= 2 && Number(value.charAt(0)) === 0)) {
                value = "0";
            }

            if (Number(value) < 0) {
                value = "0";
            } else if (Number(value) > 5000) {
                value = "5000";
            }

            if (value !== this.state.value) {
                this.setState({ value })
                this.props.onChange(value)
            }
        } else if (this.props.isPassword) {
            const value = event.target.value

            if (value !== this.state.value) {
                this.checkPasswords(value)
                this.setState({ value })
                this.props.onChange(value)
            }
        } else {
            const value = event.target.value

            if (value !== this.state.value) {
                this.setState({ value })
                this.props.onChange(value)
            }
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter' && this.props.onSubmit && this.state.value) {
            this.props.onSubmit(this.state.value)
        }
    }

    checkPasswords(value) {
        var password = value; // Берем пароль из формы
        var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
        var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
        var digits = "0123456789"; // Цифры
        //var specials = '+&@#%?=~_!' //"!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы
        var minLength = /^.{10,}$/
        /* simple popular passwords */
        let simplePass = [
            'qwerty', 'qwerty123456', 'qwerty123', 'qwerty12345', '0123456789', '123456789', 'admin', '11111111',
            'qwerty1', 'qwerty12', 'qwerty1234', 'admin1', 'admin12', 'admin123', 'admin1234', 'admin12345',
            'pass1', 'pass12', 'pass123', 'pass1234', 'pass12345', 'password1', 'password12', 'password123',
            'password1234', '1q2w3e4r', '1q2w3e4r5t', 'Qwerty1', 'Qwerty12', 'Qwerty1234', 'Qwerty12345',
            'qWerty', 'qwErty', 'qweRty', 'qwerTy', 'qwertY', 'QWERTY', 'QWERTY1', 'QWERTY12', 'QWERTY123', 
            'ytrewq', 'YTREWQ', '9876543210', '159753', '258456', '1234567890-=', '=-0987654321',
            'root', 'admin', 'admin123456', '123456', 'a123456', 'password', '1qaz@WSX', '1q2w3e', '1q2w3e4r5t6y',
            'Qwerty123', 'Qwerty', '1234567890', 'admin123', '00000000', 'user', 'noname', 'unknown', 'qwertyuiop',
        ];

        var is_s = false; // Есть ли в пароле буквы в нижнем регистре
        var is_b = false; // Есть ли в пароле буквы в верхнем регистре
        var is_d = false; // Есть ли в пароле цифры
        //var is_sp = false; // Есть ли в пароле спецсимволы
        var is_min = false; // is min lenght password
        for (var i = 0; i < password.length; i++) {
            /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
            if (!is_s && s_letters.indexOf(password[i]) !== -1) is_s = true;
            else if (!is_b && b_letters.indexOf(password[i]) !== -1) is_b = true;
            else if (!is_d && digits.indexOf(password[i]) !== -1) is_d = true;
            //else if (!is_sp && specials.indexOf(password[i]) !== -1) is_sp = true;
            else if (!is_min && (password.length >= minLength)) is_min = true;
        }
        var rating = 0;
        var text = "";
        if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
        if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
        if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
        //if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
        if (is_min) rating++; // if password min length 10 chars, то увеличиваем рейтинг сложности
        /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */
        if (simplePass.includes(password)) text = <span className={styles.checkPass__red}>Простой</span>;
        else if (password.length >= 32 && rating >= 2) text = <span className={styles.checkPass__green}>Сложный</span>;
        else if (password.length < 6 && rating < 3) text = <span className={styles.checkPass__red}>Простой</span>;
        else if (password.length < 6 && rating >= 3) text = <span className={styles.checkPass__yellow}>Средний</span>;
        else if (password.length >= 8 && rating < 3) text = <span className={styles.checkPass__yellow}>Средний</span>;
        else if (password.length >= 8 && rating >= 3) text = <span className={styles.checkPass__green}>Сложный</span>;
        else if (password.length >= 6 && rating === 1) text = <span className={styles.checkPass__red}>Простой</span>;
        else if (password.length >= 6 && (rating > 1 && rating < 4)) text = <span className={styles.checkPass__yellow}>Средний</span>;
        else if (password.length >= 6 && rating === 4) text = <span className={styles.checkPass__green}>Сложный</span>;
        //alert(text); // Выводим итоговую сложность пароля
        
        return text; //false; // Форму не отправляем
    }

    renderSimmplePass() {
        return (this.state.value !== '' && this.props.isPassword) ? (
            <div className={classNames(styles.checkPass)}>
                {this.checkPasswords(this.state.value)}
            </div>
        ) : null
    }

    render() {
        const classNamePlaceholder = classNames(styles.placeholder, {
            [styles.isVisible]: this.state.isPlaceholderVisible,
        })

        const classNameBase = classNames(styles.base, {
            [styles.isError]: this.props.isError,
        })

        const classNameInput = classNames(styles.input, {
            [styles.password]: this.props.isPassword,
            [styles.simple]: !this.props.isPassword || this.props.isPassword===null,
        })

        return (
            <div className={classNameBase}>
                <div className={classNamePlaceholder}>{this.props.placeholder}</div>
                <input
                    ref={(c) => this._input = c}
                    className={classNameInput}
                    disabled={this.props.isDisabled}
                    //type={this.props.type ? this.props.type : ''}
                    //min={this.props.type ? 1 : ''}
                    //max={this.props.type ? 5000 : ''}
                    maxLength={this.props.type ? 4 : ''}
                    value={this.props.value ? this.props.value : ''}
                    onFocus={() => this.setFocused(true)}
                    onBlur={() => this.setFocused(false)}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                />
                {this.renderSimmplePass()}
            </div>
        )
    }
}
