import React, { Component } from 'react';

import Back from '../../components/Buttons/Back/Back';
import NewsBlock from '../../containers/NewsBlockWrapper/NewsBlockWrapper';
import Main from '../../components/Main/Main';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';

import styles from './News.module.css';
import { getUserRole } from '../../utils/functions';


export default class News extends Component {
  componentDidMount() {
    document.title = `Новости Мир танков. TANKIMODS.NET - моды для Мир танков`
  }

  renderTitleMajor() {
    let title = <span>Новости</span>
    return (
      <TitleMajor>{title}</TitleMajor>
    )
  }
  
  render() {
    if ((!window.__URLS__.pages.news) && (getUserRole() !== 'admin')) {
      return <UnavailablePage />
    } else {
    return (
      <Main>
        <div className={styles.head}>
          <header>
            <Back id="Back_base" />
            {this.renderTitleMajor()}
          </header>
        </div>
        <div className={styles.body}>
          <div className={styles.inner}>
            <NewsBlock />
          </div>
        </div>
      </Main>
    )
    }
  }
}
