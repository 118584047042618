import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {NOTIFICATIONS_NOT} from './translations';
import { getUsername } from '../../utils/functions';

import Notifys from './components/Notifys/Notifys';

import styles from './UserMenu.module.css';
import { urls } from '../../utils/routing';
//import './UserMenu.css';

export default class UserMenu extends Component {
    static propTypes = {
        count: PropTypes.number,
        notifications: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            author: PropTypes.string,
            mod_id: PropTypes.number,
            title: PropTypes.string,
            spa_id: PropTypes.number,
            is_updated: PropTypes.bool,
            created_at: PropTypes.string,
            content: PropTypes.string,
        })),

        isOpened: PropTypes.bool.isRequired,

        onCloseMenu: PropTypes.func.isRequired,
        fetchNotifications: PropTypes.func.isRequired,
    }
    
    constructor(props) {
        super(props)

        this.state = {
            notificationsCount: 0, // данные заменяет при загрузке из базы юзера
            notificationsActive: false, // если что-то в базе есть, то будет это менятся
            notificationsNew: false, // если что-то в базе есть, то будет это менятся
            
            settingsIsChecked: true, // отвечает за чек бокса input, по умолчанию true, но данные сохраняются в базу к юзеру
            settingsIsOpened: false // отвечает за отображение|скрытие настроек
        }

        this.notifyIsNew = false;
        this.notifyNewCount = 0;
    }

    componentDidMount() {
        this.props.fetchNotifications(getUsername())
        this.setNotifyParameters()
    }

    componentWillUnmount() {
        //
    }

    componentDidUpdate() {
        this.renderNotifyBody();
        setTimeout(() => this.setNotifyParameters(), 1000);
    }

    setNotifyParameters = () => {
        if (!this.props.notifications) {
            this.props.fetchNotifications(getUsername())
        } else {
            if (this.props.notifications.filter((item) => item.isUpdated === true).length > 0) {
                this.notifyIsNew = true;
                this.notifyNewCount = this.props.notifications.filter((item) => item.isUpdated === true).length;
            }
        }
    }

    breakNotifyPatameters = () => {
        this.notifyIsNew = false;
        this.notifyNewCount = 0;
    }

    handleMouseMoveNotifyEmpty = () => {
        //setTimeout(() => this.setState({notificationsCount: 0, notificationsActive: false, notificationsNew: false}), 1000);
        //setTimeout(() => this.props.onChangeNotifyActive(getUsername()), 1000);
    }

    handleClickSettingsShow = () => {
        this.setState({settingsIsOpened: true});
    }

    handleClickBack = () => {
        this.setState({settingsIsOpened: false});
    }

    handleChangeNotify = () => {
        this.setState({settingsIsChecked: !this.state.settingsIsChecked});
        this.addClassSettingsMessage();
        this.unavailableButton();
    }

    addClassSettingsMessage() {
        setTimeout(() => {
            document.getElementById('settings-message_active').classList.toggle(styles.notifications_settings__message__active);
        }, 0);
        setTimeout(() => {
            document.getElementById('settings-message_active').classList.toggle(styles.notifications_settings__message__active);
        }, 2000)
    }

    unavailableButton() {
        setTimeout(() => {
            document.getElementById('notifications-settings__show').classList.toggle(styles.button__no_hover);
        }, 0);
        setTimeout(() => { // .disabled = true;
            document.getElementById('notifications-settings__show').classList.toggle(styles.button__no_hover);
        }, 2000)
    }

    // отображает количество оповещений еще не прочитанных
    renderNotifications = () => {
        const classNotifications = classNames(styles.link,
            styles.link__important,
            styles.notifications_new,
            {[styles.cursor_pointer]: this.notifyIsNew}, // this.state.notificationsActive
            {[styles.cursor_default]: !this.notifyIsNew}, // this.state.notificationsActive
            {[styles.notifications_new__hidden]: !this.notifyIsNew},
            {[styles.notifications_new__active]: this.notifyIsNew},
            "js-cm-show-unread-notification",
        );
        const classNotifyNumber = classNames(styles.notifications_unread_number,
            {[styles.notifications_unread_number__active]: this.notifyIsNew}, // this.state.notificationsNew
            "js-notification-number"
        )

        return (
            <Link to onMouseMove={this.handleMouseMoveNotifyEmpty} className={classNotifications} 
                data-active-class={styles.notifications_new__active} 
                data-hidden-class={styles.notifications_new__hidden}>
                (<span className={classNotifyNumber}>{this.notifyNewCount}</span>)
            </Link>
        )
    }

    renderCMBody = () => {
        const classNotifyEmpty = classNames(styles.notifications_empty,
            styles.notifications_empty__show,
            {[styles.notifications_empty__hidden]: !this.notifyIsNew},
            {[styles.notifications_empty__active]: this.notifyIsNew},
            //styles[`notifications_empty__${notifyActive}`],
            "js-cm-notifications-empty-panel",
        );

        if (this.notifyIsNew) {
            return (
                <React.Fragment>
                    <div className={classNames(styles.notifications_panel, styles.notifications_list__show)}>
                        <Link to={urls.logout} reloadDocument className={classNames(styles.link, styles.notifications_panel__link, "js-cm-logout")}>Выйти</Link>
                        <Link to onClick={this.handleClickSettingsShow} className={classNames(styles.link, styles.notifications_panel__link, "js-notifications-settings")}>Настройки</Link>
                        <span className={styles.notifications_panel__text}>Уведомления</span>
                        {this.renderNotifications()}
                    </div>
                    <Notifys notifications={this.props.notifications} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                <div className={classNames(styles.notifications_panel, styles.notifications_list__show)}>
                    <Link to={urls.logout} reloadDocument className={classNames(styles.link, styles.notifications_panel__link, "js-cm-logout")}>Выйти</Link>
                    <Link to onClick={this.handleClickSettingsShow} className={classNames(styles.link, styles.notifications_panel__link, "js-notifications-settings")}>Настройки</Link>
                    <span className={styles.notifications_panel__text}>Уведомления</span>
                    {this.renderNotifications()}
                </div>
                <div className={classNotifyEmpty} data-active-class={styles[`notifications_empty__active`]} data-hidden-class={styles[`notifications_empty__hidden`]}>
                    <div className={styles.notifications_empty__logo}></div> {NOTIFICATIONS_NOT}
                </div>
                </React.Fragment>
            )
        }
        //let notifyBlock = <span dangerouslySetInnerHTML={{__html: `<div class=${styles.notifications_empty__logo}></div> ${NOTIFICATIONS_NOT}`}}></span>
        /*if (notifyActive === 'hidden') {
            notifyBlock = <span dangerouslySetInnerHTML={{__html: `<div class=${styles.notifications_empty__logo}></div> ${NOTIFICATIONS_NOT}`}}></span>
        } else {
            notifyBlock = <span dangerouslySetInnerHTML={{__html: `<span>New version is available!</span>`}}></span>;//<div class={styles.notifications_empty__logo}></div>
        }*/
    }

    renderSettings = () => {        
        let classIsActive
        if (this.state.settingsIsOpened) {
            classIsActive = styles.notifications_settings__hidden;
        } else {
            classIsActive = styles.notifications_settings__active;
        }
        let classSettingsMessage = classNames(styles.notifications_settings__message, 
            "js-notifications-settings__message"
        )

        return (
            <div className={classNames(styles.notifications_settings, "js-notifications-settings", classIsActive)} data-active-class={styles.notifications_settings__active} data-hidden-class={styles.notifications_settings__hidden}>
                <strong className={styles.notifications_settings__title}>Настройки</strong>
                <form action="#" className={classNames(styles.notifications_settings__form, "js-notifications-settings")}>
                    <div className={styles.notifications_settings__item}> 
                        <input checked={this.state.settingsIsChecked} className={classNames(styles.notifications_settings_check, "js-desktop-notifications__option")} type="checkbox" id="notifications-switcher" data-tmcm-option="desktop_notifications_refused" onChange={this.handleChangeNotify} />  
                        <label className={styles.notifications_settings__label} htmlFor="notifications-switcher">Включить уведомления в браузере</label>
                    </div>
                    <span className={classSettingsMessage} id="settings-message_active" data-active-class={styles.notifications_settings__message__active}>Изменения сохранены</span>
                </form>
                <Link to id="notifications-settings__show" className={classNames(styles.button, styles.notifications_settings__back, "js-notifications-settings__back")} data-interface="notifications-settings__show" onClick={this.handleClickBack}>Назад</Link>
            </div>
        )
    }

    renderNotifyBody = () => {
        // если открыты настройки, то отображаем Настройки, если нет - Уведомления
        return this.state.settingsIsOpened ? this.renderSettings() : this.renderCMBody()
    }

    render() {
        const classNameCM = classNames(styles.container_user_menu, {
            [styles.show]: this.props.isOpened,
        })

        return (
            <div id="cm_user_menu" className={classNameCM}>
                <div className={classNames(styles.wrapper_user_menu, styles.cm_border__top)}>
                    <div className={styles.notifications_dropdown_wrapper}>
                        {this.renderNotifyBody()}
                    </div>
                    {/* <div className={styles.footer}></div> */}
                </div>
            </div>
        )
    }
}
