import React, { Component } from 'react'

import { getUserRole } from '../../utils/functions';

import Back from '../../components/Buttons/Back/Back';
import Main from '../../components/Main/Main';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';

import styles from './TermsOfUse.module.css';
import TitleMajor from '../../components/TitleMajor/TitleMajor';


export const TERMSOFUSE_TITLE = `Правила модов`;

export default class TermsOfUse extends Component {
    componentDidMount() {
        document.title = `Правила модов. TANKIMODS.NET - моды для Мир танков`
    }

    render() {
        if ((!window.__URLS__.pages.termsofuse) && (getUserRole() !== 'admin')) {
            return <UnavailablePage />
        } else {
        return (
        <Main>
            <div className={styles.base}>
                <div className={styles.head}>
                    <Back />
                    <TitleMajor>{TERMSOFUSE_TITLE}</TitleMajor>
                </div>
                <div className={styles.body}>
                    <div className={styles.inner}>
                        <div className={styles.Content_base}>
                            <div>
                                <p><strong>Внимание! Разрабатывая, распространяя и (или) используя моды для игр Lesta Games, Вы соглашаетесь соблюдать настоящие Правила, нарушение которых может привести к временной или бессрочной блокировке Вашего аккаунта и даже к судебному разбирательству в отношении Вас, если Вы нарушите применимое законодательство, в том числе об авторском праве.</strong></p>
                                <ol>
                                    <li>
                                        <h2>ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                                        <p>Настоящие Правила регулируют разработку, распространение и использование игровых модификаций (далее - Модов) в Играх компании Lesta Games (далее - «<strong>Lesta</strong>»). Разработка, распространение и использование Модов в нарушение настоящих Правил запрещается. Пользователи вправе самостоятельно распространять и использовать Моды, опубликованные на Сайте, в соответствии с настоящими Правилами.</p>
                                    </li>
                                    <li>
                                        <h2>ЗАПРЕЩЕННЫЕ МОДЫ</h2>
                                        <p>Моды, которые нарушают права третьих лиц, настоящие Правила или предоставляют чрезмерное преимущество в Игре, запрещены (далее – «<strong>Запрещенные Моды</strong>»). Примерами Модов, предоставляющих чрезмерное преимущество, являются:</p>
                                        <ul>
                                            <li>Модификации, обозначающие позиции противника способом, отличным от реализованного в клиенте игры. Моды, которые отмечают разрушенные объекты на игровом поле или мини-карте, изменяют способ отображения трассеров снарядов САУ или рассчитывают и отображают позицию САУ противника по её трассерам, показывают засвеченные машины, даже если игрок в них не целится и не направляет прицел.</li>
                                            <li>Модификации, которые облегчают уклонение от выстрелов противника благодаря определению точки прицеливания противника или проецированию предполагаемой траектории полёта снаряда на игровое поле, например, с помощью луча, исходящего из области его модели.</li>
                                            <li>Модификации, которые сообщают о перезарядке машин противника и отображают их таймеры перезарядки.</li>
                                            <li>«Умные» прицелы, которые предлагают больше возможностей, чем стандартный функционал захвата цели, реализованный в клиенте. В том числе прицелы, которые автоматически наводятся на слабо бронированные или уязвимые места машин или на машины противника в целом, а также фиксируют прицел на цели за препятствием или рассчитывают упреждение за игрока.</li>
                                            <li>Модификации, которые позволяют автоматически использовать стандартное (не премиум) снаряжение до оглашения результата боя.</li>
                                            <li>Модификации, изменяющие прозрачность объектов на игровом поле.</li>
                                            <li>Модификации, которые отображают какие-либо метки или индикаторы на игровом поле в том месте, где противник был замечен кем-либо из команды игрока.</li>
                                            <li>Модификации, которые отображают направление орудий каким-либо образом (например, в виде линии или сектора) на мини-карте.</li>
                                            <li>Модификации, которые указывают каким-либо образом направление на ближайших противников, находящихся вне поля зрения игрока.</li>
                                            <li>Модификации, которые указывают какими-либо образом (например, метками, объектами или индикаторами) местоположение противника на игровом поле за границей отрисовки.</li>
                                            <li>Любые модификации, изменяющие параметры техники или игровых объектов, влияющие на геймплей и нарушающие Правила игры.</li>
                                        </ul>
                                        <p>Приведенный выше список Запрещенных Модов не является исчерпывающим и может быть изменен Lesta в любой момент.</p>
                                    </li>
                                    <li>
                                        <h2>РАЗРАБОТКА, ИСПОЛЬЗОВАНИЕ И РАСПРОСТРАНЕНИЕ МОДОВ. ЛИЦЕНЗИЯ LESTA</h2>
                                        <p><strong>Разработка.</strong> Пользователи вправе разрабатывать Моды, не относящиеся к Запрещенным. Lesta выдает Пользователям ограниченную, неисключительную, отзывную лицензию без права уступки и сублицензирования, действующую на территории всего мира, на воспроизведение, копирование, изменение Контента исключительно для создания Модов к Играм в соответствии с настоящими Правилами («<strong>Лицензия Lesta</strong>»). Лицензия Lesta действительна при условии соблюдения настоящих Правил и других обязательных документов, применимых к продуктам и услугам Lesta (в том числе Лицензионного соглашения, Политики Конфиденциальности, Правил Игры, Правил Форума, Правил использования Сайта Модов и др.).</p>
                                        <p>Пользователи не вправе использовать Контент для целей, прямо не указанных в настоящих Правилах или другой лицензии Lesta. Пользователям запрещается удалять уведомления о праве интеллектуальной собственности, размещенные в (на) Контенте.</p>
                                        <p>Кроме того, Лицензия Lesta действует при условии, что:</p>
                                        <ul>
                                            <li>Пользователь получил Контент от компании Lesta (или уполномоченных ей лиц) и не получал (не предпринимал попыток получить) Контент от других лиц;</li>
                                            <li>Lesta не гарантирует, что Контент будет доступен постоянно, во всех странах мира, в определенный момент или в течение определенного промежутка времени (за исключением случаев, когда иное прямо оговорено в отношении конкретных продуктов или услуг);</li>
                                            <li>Lesta не гарантирует, что использование Контента не нарушит права третьих лиц, в частности права лицензиаров Lesta. По требованию правообладателя или по нашему усмотрению мы вправе удалить Мод с Сайта и потребовать от Пользователя прекратить распространение Мода, содержащего Контент, который больше не может быть использован при создании Модов. Lesta вправе в любой момент запретить использование какого-либо Контента в разработке Модов;</li>
                                            <li>Lesta вправе обновлять, удалять и иным образом изменять Контент в случае необходимости.</li>
                                        </ul>
                                        <p>Все права на Контент, Игры и их копии принадлежат компании Lesta и ее лицензиарам и не могут быть использованы третьими лицами, если иное прямо не указано в настоящих Правилах. Контент и Игры защищены законодательством об авторском праве, международными договорами и конвенциями об авторском праве и другими нормативными правовыми актами. Кроме того, Контент и Игры содержат материалы, полученные по лицензии. Лицензиары Lesta могут защищать свои права в случае нарушения настоящих Правил.</p>
                                        <p>Если при разработке Мода Пользователь использует контент третьих лиц, он обязан получить все необходимые права у владельца такого контента. Lesta не проверяет Моды на предмет соблюдения Интеллектуальных прав третьих лиц и законодательства и не несет ответственности за такие нарушения. Вы можете загрузить Мод, автором которого Вы не являетесь, при условии, что Вы обеспечили ознакомление автора Мода с данными Правилами и предоставление Вам прав на размещение Мода на Сайте согласно Правилам. Вы несёте полную ответственность за любые нарушения Интеллектуальных прав третьих лиц и законодательства, которые могут возникнуть в результате размещения Мода на Сайте и его последующего использования.</p>
                                        <p><strong>Распространение.</strong> Пользователи вправе распространять Моды, не относящиеся к Запрещенным. Lesta не осуществляет модерацию Модов, загруженных на Сайт. Lesta не осуществляет модерацию Модов на предмет законности использования в них контента третьих лиц (обеспечение законности такого использования является обязанностью Пользователя). Моды распространяются бесплатно. Распространение Модов за плату или иное материальное и (или) нематериальное вознаграждение не допускается.</p>
                                        <p><strong>Использование.</strong> Конечные Пользователи вправе использовать Моды, не относящиеся к Запрещенным. В случае обнаружения Мода, который содержит рекламу или обеспечивает ее показ в игровом клиенте, Пользователь обязан незамедлительно прекратить использование такого Мода и сообщить службе поддержки Lesta его название и источник.</p>
                                    </li>
                                    <li>
                                        <h2>ЛИЦЕНЗИЯ ПОЛЬЗОВАТЕЛЯ</h2>
                                        <p>Загружая Мод на Сайт, Пользователь тем самым предоставляет Lesta неисключительное право на использование, воспроизведение, изменение, создание производных произведений, распространение, передачу, перекодировку, перевод, передачу в эфир и сообщение по иным каналам связи, публичный показ и воспроизведение загружаемого Мода и производных произведений на территории всего мира в связи с оперированием, распространением, продвижением Игр и других продуктов и услуг Lesta («<strong>Лицензия Пользователя</strong>»).</p>
                                        <p>Если Пользователь предоставляет Lesta обратную связь или предложения в отношении Игр, Сайта, других продуктов и услуг Lesta, Lesta вправе использовать такую обратную связи и предложения любым способом без каких-либо встречных обязательств перед Пользователем.</p>
                                        <p>Если Вы удалите свой аккаунт, некоторая информация о Вас будет сохранена в анонимном виде. После удаления аккаунта Моды, которые Вы опубликовали на Сайте Модов, останутся доступными для скачивания и использования. В авторстве модов, опубликованных с удалённого аккаунта, будет указан неизвестный пользователь. Мы не контролируем, как другие пользователи используют информацию, опубликованную на наших ресурсах.</p>
                                    </li>
                                    <li>
                                        <h2>ГАРАНТИИ И ВОЗМЕЩЕНИЕ УБЫТКОВ</h2>
                                        <p>Пользователь гарантирует, что он обладает правами, необходимыми для выдачи компании Lesta и другим лицам Лицензии Пользователя, в том числе Интеллектуальными правами, правом собственности и личными правами в отношении Модов. Пользователь гарантирует, что он является создателем Модов, которые он загружает на Сайт.</p>
                                        <p>Пользователь также гарантирует, что Моды, их загрузка на Сайт и предоставление прав на них не нарушают какие-либо договоры и применимое законодательство.</p>
                                        <p>В дополнение к средствам правовой защиты, доступным Lesta, Пользователь обязуется полностью оградить Lesta, ее сотрудников, аффилированных лиц и партнеров от прямой и косвенной ответственности, претензий, требований, убытков (включая расходы на юридические и другие профессиональные услуги) и штрафных санкций без обязанности принимать меры по их снижению и независимо от способа их возникновения, если они полностью или частично, прямо или косвенно вызваны нарушением или предполагаемым нарушением Пользователем настоящих Правил, Интеллектуальных прав Lesta или третьих лиц или применимого законодательства.</p>
                                        <p>В случае получения претензии в отношении Lesta по поводу Модов Пользователь обязан незамедлительно уведомить об этом Lesta. Lesta вправе принять решение о том, что Пользователь будет самостоятельно осуществлять защиту по указанной претензии за свой счет и от своего имени, но под окончательным контролем Lesta. Пользователь не вправе пытаться самостоятельно урегулировать претензию без предварительного письменного согласия Lesta.</p>
                                    </li>
                                    <li>
                                        <h2>ОТВЕТСТВЕННОСТЬ</h2>
                                        <p>В случае использования Запрещенных Модов в первый раз Пользователю будет вынесено предупреждение и запрещен доступ к Играм сроком на семь дней (в дополнение к иной ответственности за нарушение настоящих Правил, Интеллектуальных прав или применимого законодательства).</p>
                                        <p>Повторное нарушение влечет бессрочную блокировку доступа к Играм.</p>
                                        <p>Компания Lesta не обязана предоставлять доказательства использования Запрещенных Модов или сообщать, какой вид Запрещенных Модов был использован, даже в случае получения соответствующего запроса от Пользователя.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
        )
        }
  }
}
