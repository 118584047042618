import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ButtonGrayOutlineSmall.module.css';

export default class ButtonGrayOutlineSmall extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        glitch: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.glitch]: this.props.glitch,
        })

        return (
            <button className={classNameBase} onClick={this.props.onClick}>
                <span className={styles.content}>{this.props.children}</span>
            </button>
        )
    }
}
