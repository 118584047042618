import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { urls } from '../../../utils/routing';

import styles from './Back.module.css';

export default class Back extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    glitch: PropTypes.bool,
    gtmTag: PropTypes.string,
  }

  render() {
    return (
      <Link className={styles.base} to={urls.landing}>
        <span>На главную страницу</span>
      </Link>
    )
  }
}
