//import React from 'react';
import { connect } from 'react-redux';

import { signin } from '../../actions/CommonMenu';

import SignIn from '../../components/SignIn/SignIn';


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    isErrorData: state.currentAccount.isErrorData,
})

const mapDispatchToProps = (dispatch) => ({
    onSignIn(login, password) {
        dispatch(signin(login, password))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
