import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash'

import styles from './ButtonBlueSmall.module.css';

export default class ButtonBlueSmall extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        glyph: PropTypes.oneOf(['plus']),
        glitch: PropTypes.bool,
        gtmTag: PropTypes.string,
        isWide: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = (event) => {
        /*if (this.gtmTagRef) {
            this.gtmTagRef.click()
        }*/
        this.props.onClick(event)
    }

    renderGtmTag() {
        return !isUndefined(this.props.gtmTag) ? (
            <div className={classNames(this.props.gtmTag)} ref={c => (this.gtmTagRef = c)} />
        ) : null
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles[`base__${this.props.glyph}`]]: this.props.glyph,
            [styles.isWide]: this.props.isWide,
            [styles.glitch]: this.props.glitch,
        })

        return (
            <React.Fragment>
                {this.renderGtmTag()}
                <button className={classNameBase} onClick={this.handleClick}>
                    <span className={styles.content}>{this.props.children}</span>
                </button>
            </React.Fragment>
        )
    }
}
