import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'jquery';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { enableBatching } from 'redux-batched-actions';
import persistState from 'redux-localstorage';
import { composeWithDevTools } from '@redux-devtools/extension';

import middlewares, { history } from './middlewares';
import { registerFormattingLocales, activateFormattingLocale } from './utils/formatting';
import { preloadedStateValidator } from './utils/validators';
import rootReducer from './reducers';

import AppWrapper from './containers/AppWrapper/AppWrapper';

import reportWebVitals from './reportWebVitals';

import './assets/stylesheets/index.css'; // style of page
import './index.css'; // style of page


registerFormattingLocales()
activateFormattingLocale(window.__PRELOADED_STATE__.currentAccount.lang)

const enhancer = compose(
  applyMiddleware(...middlewares),
  persistState('persistedValues', { key: 'tmPersistedValues' }), // в local storage записываем этот массив, и можно оттуда с ним работать
)

const store = createStore(
  enableBatching(rootReducer),
  preloadedStateValidator(window.__PRELOADED_STATE__),
  composeWithDevTools(enhancer), //applyMiddleware(thunk)
)

syncHistoryWithStore(history, store)

const app = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <AppWrapper history={history} />
      </Router>
    </Provider>
  )
}

/* рендерим наш дом (сайт) */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // потом убрать стрикт моде
  //<React.StrictMode>
    app(store)
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
