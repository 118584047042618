export const TITLE_SIGN_IN = `Sign In. «TANKIMODS.NET»`

export const ERROR_PROBLEM_CORRECT = `Неверный логин или пароль.`

export const ERROR_PROBLEM_ALL = `Ошибка авторизации. (error_code: 500)`

export const ERROR_PROBLEM_401 = `Ошибка регистрации. (error_code: 401)`

export const FIELD_SIGN_ERROR_TYPES = {
    LOGIN_MAX_LIMIT: 'Error! Max length - 50 symbols.',
    PASS_MAX_LIMIT: 'Error! Max length - 100 symbols.',
    FIELDS_EXCEPTED: `Заполните поля Логин и Пароль.`,
    FIELDS_EXCEPTED_LOGIN: `Заполните поле Логин.`,
    FIELDS_EXCEPTED_PASS: `Заполните поле Пароль.`,
}

export const SIGN_IN = `Войти`

export const ERROR_PROBLEM_SIGN_IN = `Не удаётся войти?`

export const PASSWORD_RECOVERY = `Восстановить аккаунт`