import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
//import { injectIntl, intlShape } from 'react-intl'
import { StickyContainer } from 'react-sticky';
import scrollToComponent from 'react-scroll-to-component';
import { cloneDeep, difference, each, isEmpty, set, intersection, omit, without } from 'lodash';

import settings from '../../settings';

import { 
    FIELD_VALIDATION_ERROR_TYPES, 
    MOD_STATUS_TYPES, 
    SCROLL_TO_COMPONENTS_PARAMETERS 
} from '../../utils/constants';
import { browserName } from '../../utils/helpers';
import { AVAILABLE_LANGUAGES } from '../../utils/i18n';
import {
    getPrivacyPolicyUrlByRealm,
    getTermsOfUsePortal,
    //pushHistoryWithErrorNotFound,
    urls,
} from '../../utils/routing';

import Back from '../Buttons/Back/Back';
import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import ButtonGrayOutlineLarge from '../Buttons/ButtonGrayOutlineLarge/ButtonGrayOutlineLarge';
import Divider from '../Divider/Divider';
import EditAuthorName from '../Edit/EditAuthorName/EditAuthorName';
import EditCategories from '../Edit/EditCategories/EditCategories';
import EditChangelog from '../Edit/EditChangelog/EditChangelog';
import EditChangelogAdd from '../Edit/EditChangelogAdd/EditChangelogAdd';
import EditChangelogList from '../Edit/EditChangelogList/EditChangelogList';
import EditComment from '../Edit/EditComment/EditComment';
import EditCoverUpload from '../Edit/EditCoverUpload/EditCoverUpload';
import EditGameVersion from '../Edit/EditGameVersion/EditGameVersion';
import EditLanguageAdd from '../Edit/EditLanguageAdd/EditLanguageAdd';
import EditLanguageForm from '../Edit/EditLanguageForm/EditLanguageForm';
import EditModVersion from '../Edit/EditModVersion/EditModVersion';
import EditNotifications from '../Edit/EditNotifications/EditNotifications';
import EditScreenshots from '../Edit/EditScreenshots/EditScreenshots';
import EditUploadMod from '../Edit/EditUploadMod/EditUploadMod';
import ErrorBlocked from '../ErrorBlocked/ErrorBlocked';
import Main from '../Main/Main';
import Notification from '../Notification/Notification';
import NotificationBlockedUser from '../NotificationBlockedUser/NotificationBlockedUser';
import TitleMajor from '../TitleMajor/TitleMajor';

import {
    CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT,
    CREATE_NOTIFICATION_REQUIRED_CONTENT,
    PUBLISH_ATTENTION_1,
    PUBLISH_ATTENTION_2,
    SAVE_AS_DRAFT,
    SEND_TO_CHECK_BUTTON,
    TO_MODDER_SECTION,
} from '../../translations';

import {
    CREATE_INFO,
    CREATE_PAGE_TITLE,
    messages,
} from './translations';

import styles from './CreateMod.module.css';
import UnavailablePageWrapper from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';
import { getUserRole } from '../../utils/functions';


export default class CreateMod extends React.Component {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        })).isRequired,
        changelogHistory: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            content: PropTypes.string.isRequired,
            version: PropTypes.string.isRequired,
        })).isRequired,
        gameVersions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                version: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        isEdgeAlertAccepted: PropTypes.bool.isRequired,
        isIEAlertAccepted: PropTypes.bool.isRequired,
        isUserBanned: PropTypes.bool.isRequired,
        isUserLoggedIn: PropTypes.bool.isRequired,
        isUserStatisticsSufficient: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        realm: PropTypes.string.isRequired,

        history: ReactRouterPropTypes.history.isRequired,

        //intl: intlShape,

        onChangelogConfirmationDialogShow: PropTypes.func.isRequired,
        onChangelogHistoryItemAdd: PropTypes.func.isRequired,
        onChangelogHistoryItemEdit: PropTypes.func.isRequired,
        onChangelogHistoryItemsReordered: PropTypes.func.isRequired,
        onComponentWillMount: PropTypes.func.isRequired,
        onFormSubmit: PropTypes.func.isRequired,
        onShowEdgeConfirmationDialog: PropTypes.func.isRequired,
        onShowIEConfirmationDialog: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            descriptionLanguages: [],
            fieldErrors: {
                authorName: null,
                categories: null,
                changelog: null,
                comment: null,
                modFile: null,
                modVersion: null,
                byLanguages: {
                    en: {
                        title: null,
                        description: null,
                        installationGuide: null,
                    },
                    ru: {
                        title: null,
                        description: null,
                        installationGuide: null,
                    },
                },
            },
            hasError: false,
        }

        this.formData = {
            byLanguages: {
                en: {
                    title: '',
                    description: '',
                    installationGuide: '',
                },
                ru: {
                    title: '',
                    description: '',
                    installationGuide: '',
                },
            },
            authorName: null,
            categories: [],
            changelog: null,
            changelogHistory: [],
            comment: null,
            coverFileId: null,
            coverFileToken: null,
            gameVersionId: null,
            modFileId: null,
            modFileToken: null,
            modVersion: '',
            screenshots: [],
        }

        this.formErrors = {
            categories: FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
            modFile: FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
            modVersion: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            byLanguages: {
                en: {
                    title: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                    description: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                },
                ru: {
                    title: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                    description: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                },
            },
        }

        this.refsWithErrors = [
            { field: 'modFile', ref: React.createRef(), hasError: false },
            { field: 'modVersion', ref: React.createRef(), hasError: false },
            { field: 'ruLanguage', ref: React.createRef(), hasError: false },
            { field: 'enLanguage', ref: React.createRef(), hasError: false },
            { field: 'categories', ref: React.createRef(), hasError: false },
        ]
    }

    UNSAFE_componentWillMount() {
        if ((browserName.ie11 || browserName.ie10) && this.props.isIEAlertAccepted === false) {
            this.props.onShowIEConfirmationDialog()
        }

        if (browserName.edge && this.props.isEdgeAlertAccepted === false) {
            this.props.onShowEdgeConfirmationDialog()
        }

        // пока эту функцию отключаем
        /*if (!this.props.isUserLoggedIn) {
            pushHistoryWithErrorNotFound(this.props.history)
            return
        }*/

        this.setState({
            descriptionLanguages: [this.props.language],
        })
        this.props.onComponentWillMount()

        document.title = messages // messages.createModTitle
    }

    handleFileChange = (data, errorType) => {
        let combinedErrorType = null
        if (errorType) {
            combinedErrorType = errorType
        } else if (data === null) {
            combinedErrorType = FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED
        }

        set(this.formData, 'modFileId', data ? data.id : null)
        set(this.formData, 'modFileToken', data ? data.access_token : null)
        set(this.formErrors, 'modFile', combinedErrorType)
        if (!combinedErrorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, 'modFile', null)
            this.setState({ fieldErrors: stateFieldErrors })
        }
    }

    handleGameVersionChange = (gameVersionId) => {
        set(this.formData, 'gameVersionId', gameVersionId)
    }

    handleModVersionChange = (modVersion, errorType) => {
        this.setFormValues('modVersion', modVersion, errorType)
    }

    handleCoverChange = (data) => {
        if (data === null) {
            set(this.formData, 'coverFileId', null)
            set(this.formData, 'coverFileToken', null)
        } else {
            set(this.formData, 'coverFileId', data.id)
            set(this.formData, 'coverFileToken', data.access_token)
        }
    }

    handleAddLanguageClick = (language) => {
        if (this.state.descriptionLanguages.includes(language)) {
            return
        }
        this.setState({
            descriptionLanguages: [
                ...this.state.descriptionLanguages,
                language,
            ],
        })
        set(this.formErrors, `byLanguages.${language}.title`, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT)
        set(this.formErrors, `byLanguages.${language}.description`, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT)
    }

    handleRemoveLanguageClick = (language) => {
        if (!this.state.descriptionLanguages.includes(language)) {
            return
        }

        const stateFieldErrors = cloneDeep(this.state.fieldErrors)
        set(stateFieldErrors, `byLanguages.${language}.title`, null)
        set(stateFieldErrors, `byLanguages.${language}.description`, null)
        set(stateFieldErrors, `byLanguages.${language}.installationGuide`, null)

        this.setState({
            descriptionLanguages: without(this.state.descriptionLanguages, language),
            fieldErrors: stateFieldErrors,
        })
    }

    handleTitleChange = (language, title, errorType) => {
        this.setFormValuesByLanguages('title', language, title, errorType)
    }

    handleDescriptionChange = (language, description, errorType) => {
        this.setFormValuesByLanguages('description', language, description, errorType)
    }

    handleInstallationGuideChange = (language, installationGuide, errorType) => {
        this.setFormValuesByLanguages('installationGuide', language, installationGuide, errorType)
    }

    handleChangelogChange = (changelog, errorType) => {
        this.setFormValues('changelog', changelog, errorType)
    }

    handleCategoriesChange = (categories, errorType) => {
        this.setFormValues('categories', categories, errorType)
    }

    handleAuthorNameChange = (authorName, errorType) => {
        this.setFormValues('authorName', authorName, errorType)
    }

    handleCommentChange = (comment, errorType) => {
        this.setFormValues('comment', comment, errorType)
    }

    handleScreenshotsChange = (items) => {
        if (items) {
            set(this.formData, 'screenshots', items)
        }
    }

    handleSendToCheckClick = () => {
        const fieldRules = {
            modFile: [
                FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
                FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR,
            ],
            modVersion: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            categories: [
                FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
            ],
            authorName: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const fieldByLanguagesRules = {
            title: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            description: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            installationGuide: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const stateValue = this.getValidationErrors(fieldRules, fieldByLanguagesRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.scrollToErrorField()
            this.setState(stateValue)
            return
        }

        set(this.formData, 'status', MOD_STATUS_TYPES.PENDING_REVIEW)
        this.logSubmittingOk()
        this.props.onFormSubmit(this.formData)
    }

    handleSaveAsDraftClick = () => {
        const fieldRules = {
            modFile: [
                FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR,
            ],
            modVersion: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            authorName: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const fieldByLanguagesRules = {
            title: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            description: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            installationGuide: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const stateValue = this.getValidationErrors(fieldRules, fieldByLanguagesRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.scrollToErrorField()
            this.setState(stateValue)
            return
        }

        set(this.formData, 'status', MOD_STATUS_TYPES.DRAFT)
        this.logSubmittingOk()
        this.props.onFormSubmit(this.formData)
    }

    getValidationErrors(fieldRules, fieldByLanguagesRules) {
        const stateValue = { hasError: true }
        let hasError = false

        each(fieldRules, (ruleset, field) => {
            if (ruleset.includes(this.formErrors[field])) {
                set(stateValue, `fieldErrors.${field}`, this.formErrors[field])
                this.setRefObjectWithError(field)
                hasError = true
            }
        })

        each(fieldByLanguagesRules, (ruleset, field) => {
            each(AVAILABLE_LANGUAGES, (language) => {
                const errorType = this.formErrors.byLanguages[language][field]
                if (this.state.descriptionLanguages.includes(language) && ruleset.includes(errorType)) {
                    set(stateValue, `fieldErrors.byLanguages.${language}.${field}`, errorType)
                    this.setRefObjectWithErrorsByLanguage(language)
                    hasError = true
                } else {
                    set(stateValue, `fieldErrors.byLanguages.${language}.${field}`, null)
                }
            })
        })

        return hasError ? stateValue : null
    }

    setRefObjectWithError(field) {
        const refItem = this.refsWithErrors.find(item => item.field === field)
        if (refItem) {
            refItem.hasError = true
        }
    }

    setRefObjectWithErrorsByLanguage(language) {
        this.setRefObjectWithError(`${language}Language`)
    }

    clearAllRefsErrors() {
        each(this.refsWithErrors, (item => {
            item.hasError = false
        }))
    }

    scrollToErrorField() {
        const refObject = this.refsWithErrors.find(item => item.hasError)
        if (refObject) {
            scrollToComponent(refObject.ref, SCROLL_TO_COMPONENTS_PARAMETERS)
        }
    }

    setFormValues(field, value, errorType) {
        set(this.formData, field, value)
        set(this.formErrors, field, errorType)

        if (!errorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, field, null)
            this.setState({ fieldErrors: stateFieldErrors })
        }

        this.clearAllRefsErrors()
    }

    setFormValuesByLanguages(field, language, value, errorType) {
        set(this.formData, `byLanguages.${language}.${field}`, value)
        set(this.formErrors, `byLanguages.${language}.${field}`, errorType)

        if (!errorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, `byLanguages.${language}.${field}`, null)
            this.setState({ fieldErrors: stateFieldErrors })
        }

        this.clearAllRefsErrors()
    }

    logSubmittingFailed(stateValue) {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[FAILED] submitting, stateValue: ', 'color: red;', stateValue)
        // eslint-disable-next-line no-console
        console.log('form errors', this.formErrors)
    }

    logSubmittingOk() {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[OK] submitting', 'color: green;', this.formData)
    }

    renderLanguages() {
        const isRemoveLanguageEnabled = this.state.descriptionLanguages.length > 1
        const languageBlocks = this.state.descriptionLanguages.map((language) => {
            return (
                <React.Fragment key={`edit-language-${language}`}>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <EditLanguageForm
                        externalTitleError={this.state.fieldErrors.byLanguages[language].title}
                        externalDescriptionError={this.state.fieldErrors.byLanguages[language].description}
                        externalInstallationGuideError={this.state.fieldErrors.byLanguages[language].installationGuide}
                        isRemoveLanguageEnabled={isRemoveLanguageEnabled}
                        language={language}
                        ref={(component) => {
                            this.refsWithErrors.find(item => item.field === `${language}Language`).ref = component
                        }}

                        onDescriptionChange={this.handleDescriptionChange}
                        onInstallationGuideChange={this.handleInstallationGuideChange}
                        onRemoveLanguageClick={this.handleRemoveLanguageClick}
                        onTitleChange={this.handleTitleChange}
                    />
                </React.Fragment>
            )
        })

        const availableLanguages = difference(AVAILABLE_LANGUAGES, this.state.descriptionLanguages)
        const addLanguageControl = !isEmpty(availableLanguages) ? (
            <React.Fragment>
                <div className={styles.divider}><Divider isDashed /></div>
                <EditLanguageAdd
                    isDisabled
                    selectedValue={availableLanguages[0]}
                    onAddLanguageClick={this.handleAddLanguageClick}
                />
            </React.Fragment>
        ) : null

        return (
            <React.Fragment>
                {languageBlocks}
                {addLanguageControl}
            </React.Fragment>
        )
    }

    renderNotifications() {
        if (!this.props.isUserStatisticsSufficient) {
            return
        }

        if (!this.state.hasError) {
            return (
                <EditNotifications>
                    <div className={styles.notification}>
                        <Notification type="info">
                            <p className={classNames(styles.notificationContent, styles['notificationContent__info'])}>
                                {CREATE_NOTIFICATION_REQUIRED_CONTENT}
                            </p>
                        </Notification>
                    </div>
                </EditNotifications>
            )
        }

        const errorTypeSet = new Set()
        each(omit(this.state.fieldErrors, 'byLanguages'), (errorType) => {
            errorTypeSet.add(errorType)
        })
        each(this.state.fieldErrors.byLanguages, (subObject) => {
            each(subObject, (errorType) => {
                errorTypeSet.add(errorType)
            })
        })

        const isRequiredNotificationVisible = !isEmpty(intersection(
            [FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES, FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT],
            [...errorTypeSet],
        ))

        const isMaxLengthNotificationVisible = !isEmpty(intersection(
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT],
            [...errorTypeSet],
        ))

        const requiredNotification = isRequiredNotificationVisible ? (
            <div className={styles.notification}>
                <Notification type="warning">
                    <p className={classNames(styles.notificationContent, styles['notificationContent__warning'])}>
                        {CREATE_NOTIFICATION_REQUIRED_CONTENT}
                    </p>
                </Notification>
            </div>
        ) : null

        const maxLengthNotification = isMaxLengthNotificationVisible ? (
            <div className={styles.notification}>
                <Notification type="warning">
                    <p className={classNames(styles.notificationContent, styles['notificationContent__warning'])}>
                        {CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT}
                    </p>
                </Notification>
            </div>
        ) : null

        return (
            <EditNotifications>
                {requiredNotification}
                {maxLengthNotification}
            </EditNotifications>
        )
    }

    renderContent() {
        if (this.props.isUserBanned) {
            return <ErrorBlocked history={this.props.history} />
        }

        const classNameForm = classNames(styles.form, {
            [styles.isDisabled]: !this.props.isUserStatisticsSufficient,
        })

        return (
            <div className={styles.inner}>
                <div className={styles.content}>
                    {!this.props.isUserStatisticsSufficient && (
                        <div className={styles.notificationBlockedUser}>
                            <NotificationBlockedUser history={this.props.history} />
                        </div>
                    )}

                    {this.props.isUserStatisticsSufficient && (
                        <p className={styles.info}>{CREATE_INFO}</p>
                    )}

                    <div className={classNameForm}>
                        <EditUploadMod
                            externalError={this.state.fieldErrors.modFile}
                            ref={(component) => {
                                this.refsWithErrors.find(item => item.field === 'modFile').ref = component
                            }}
                            onUploadCompleted={this.handleFileChange}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditGameVersion
                            gameVersions={this.props.gameVersions}
                            onGameVersionChange={this.handleGameVersionChange}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditModVersion
                            externalError={this.state.fieldErrors.modVersion}
                            ref={(component) => {
                                this.refsWithErrors.find(item => item.field === 'modVersion').ref = component
                            }}
                            onValueChange={this.handleModVersionChange}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditCoverUpload
                            externalError={this.state.fieldErrors.coverFile}
                            onUploadCompleted={this.handleCoverChange}
                        />

                        {this.renderLanguages()}

                        <div className={styles.divider}><Divider /></div>
                        <EditChangelog
                            externalError={this.state.fieldErrors.changelog}
                            onValueChange={this.handleChangelogChange}
                        />
                        {this.props.changelogHistory.length !== 0 && (
                            <EditChangelogList
                                historyItems={this.props.changelogHistory}
                                onHistoryItemEdit={this.props.onChangelogHistoryItemEdit}
                                onHistoryItemsReordered={this.props.onChangelogHistoryItemsReordered}
                                onConfirmationDialogShow={this.props.onChangelogConfirmationDialogShow}
                            />
                        )}
                        <EditChangelogAdd
                            hasHistory
                            onHistoryItemAdd={this.props.onChangelogHistoryItemAdd}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditCategories
                            categories={this.props.categories}
                            externalError={this.state.fieldErrors.categories}
                            ref={(component) => {
                                this.refsWithErrors.find(item => item.field === 'categories').ref = component
                            }}
                            onValueChange={this.handleCategoriesChange}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditScreenshots onScreenshotsChange={this.handleScreenshotsChange} />

                        <div className={styles.divider}><Divider /></div>
                        <EditAuthorName
                            externalError={this.state.fieldErrors.authorName}
                            onValueChange={this.handleAuthorNameChange}
                        />

                        <div className={styles.divider}><Divider /></div>
                        <EditComment
                            externalError={this.state.fieldErrors.comment}
                            onValueChange={this.handleCommentChange}
                        />

                        <div className={styles.divider}><Divider /></div>

                        {this.renderNotifications()}

                        <div className={styles.attentions}>
                            <p className={styles.attention}>
                                {PUBLISH_ATTENTION_1(getPrivacyPolicyUrlByRealm(this.props.realm))}
                            </p>
                            <p className={styles.attention}>
                                {PUBLISH_ATTENTION_2(getTermsOfUsePortal(this.props.language))}
                            </p>
                        </div>

                        <div className={styles.button}>
                            <ButtonBlueLarge
                                onClick={this.handleSendToCheckClick}
                                gtmTag={'ev_click-submit-to-review'}
                            >
                                {SEND_TO_CHECK_BUTTON}
                            </ButtonBlueLarge>
                        </div>
                        <div className={styles.button}>
                            <ButtonGrayOutlineLarge onClick={this.handleSaveAsDraftClick}>
                                {SAVE_AS_DRAFT}
                            </ButtonGrayOutlineLarge>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if ((!window.__URLS__.pages.create) && (getUserRole() !== 'admin')) {
            return <UnavailablePageWrapper />
        } else {
            return (
                <Main>
                    <div className={styles.base}>
                        <div className={styles.head}>
                            <Back caption={TO_MODDER_SECTION} to={urls.modderSection} />
                            <TitleMajor>{CREATE_PAGE_TITLE}</TitleMajor>
                        </div>
                        <div className={styles.body}>
                            <StickyContainer>
                                {this.renderContent()}
                            </StickyContainer>
                        </div>
                    </div>
                </Main>
            )
        }
    }
}

//export default CreateMod;
//export default injectIntl(CreateMod)
