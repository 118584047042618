//import React from 'react';
import { connect } from 'react-redux';

import { checkKey, signup } from '../../actions/CommonMenu';

import SignUp from '../../components/SignUp/SignUp';


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    isErrorData: state.currentAccount.isErrorData,
    accessKey: state.currentAccount.accessKey,
    accessData: state.currentAccount.accessData,
})

const mapDispatchToProps = (dispatch) => ({
    onSignUp(login, password, accessName, openkey) {
        dispatch(signup(login, password, accessName, openkey))
    },
    onKeySignUp(accessKey) {
        dispatch(checkKey(accessKey))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
