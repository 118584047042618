import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { pushHistoryWithLanding } from '../../utils/routing';
import { getCookie, deleteCookie } from '../../utils/functions';
import Main from '../Main/Main';

import styles from './Logout.module.css';
import { JWTname, localStorageName } from '../../utils/constants';

export default class Logout extends Component {
    static propTypes = {
        spaId: PropTypes.number,
        spaUsername: PropTypes.string,
        isFetching: PropTypes.bool
    };

    componentDidMount() { 
        this.getDataFromStorage()
    }

    getDataFromStorage() {
        // проверяем локальное хранилище
        let localData = localStorage.getItem(localStorageName);
        if (typeof localData !== 'undefined' && localData !== null) {
            localStorage.removeItem(localStorageName)
        }
        // проверяем куки
        if (getCookie(JWTname)) {
            deleteCookie(JWTname)
        }
        
        setTimeout(() => {
            pushHistoryWithLanding(this.props.history)
        }, 1000)
    }

    render() {
        return (
            <Main>
                <div className={styles.body}>
                    <div className={styles.block}>
                        <div className={styles.title}>
                            <span>Выход из учетной записи</span>
                        </div>
                        <div className={styles.content}>
                            <span>Пожалуйста подождите...</span>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }
}
