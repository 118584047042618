import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import styles from './ButtonGrayOutlineLarge.module.css'

export default class ButtonGrayOutlineLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        glitch: PropTypes.bool,
        gtmTag: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = () => {
        if (this.gtmTagRef) {
            this.gtmTagRef.click()
        }
        this.props.onClick()
    }

    renderGtmTag() {
        return !isUndefined(this.props.gtmTag) ? (
            <div className={classNames(this.props.gtmTag)} ref={c => (this.gtmTagRef = c)} />
        ) : null
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.glitch]: this.props.glitch,
        })

        return (
            <React.Fragment>
                {this.renderGtmTag()}
                <button className={classNameBase} onClick={this.handleClick}>
                    <span className={styles.content}>{this.props.children}</span>
                </button>
            </React.Fragment>
        )
    }
}
