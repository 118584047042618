import { authorization, prolongate } from "../actions/CurrentAccount";import { JWTname, localStorageName } from "./constants";
;

export const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        // при необходимости добавить другие значения по умолчанию
        ...options
    };
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
} 

export const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[]\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const deleteCookie = (name) => {
    setCookie(name, "", {'max-age': -1})
}

/* JWT token */
export const parseJwt = ( token ) => {
    let split = token.split('.');
	if( split.length !== 3 ) {
		console.log({'Error parse': 'Wrong number of segments'});
	}
    //let headerB64 = split[0];
    let payloadB64Url = split[1];
    //let signB64 = split[2];
	if(null === payloadB64Url) {
		console.log({'Error parse': 'Invalid claims encoding'});
	}
    var payloadB64 = payloadB64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(payloadB64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
	//let jsonPayload = urlsafeB64Decode(payloadB64Url);
	return JSON.parse(jsonPayload);
}


export const getUsername = () => {
    // получаем данные из локального хранилища и из куков
    if (getCookie(JWTname)) {
        // проверяем валидность токена в куках 
        let cookieJwt = getCookie(JWTname).split('.')
        if (cookieJwt.length === 3) {
            // достаем payload из токена в куках
            let payloadJWT_client = parseJwt(getCookie(JWTname))

            // проверяем, если время жизни токена ушло, данные не отправляем
            if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                return {'spaUsername': payloadJWT_client['spaUsername'], 'spaId': payloadJWT_client['spaId']}
            } else {
                return {'spaUsername': null, 'spaId': null}
            }
        } else {
            return {'spaUsername': null, 'spaId': null}
        }
    } else {
        return {'spaUsername': null, 'spaId': null}
    }
}

export const getUserId = () => {
    // получаем данные из локального хранилища и из куков
    if (getCookie(JWTname)) {
        // проверяем валидность токена в куках 
        let cookieJwt = getCookie(JWTname).split('.')
        if (cookieJwt.length === 3) {
            // достаем payload из токена в куках
            let payloadJWT_client = parseJwt(getCookie(JWTname))

            // проверяем, если время жизни токена ушло, данные не отправляем
            if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                return  payloadJWT_client['spaId']
            } else {
                return null
            }
        } else {
            return null
        }
    } else {
        return null
    }
}

export const getUserRole = () => {
    // получаем данные из локального хранилища и из куков
    if (getCookie(JWTname)) {
        // проверяем валидность токена в куках 
        let cookieJwt = getCookie(JWTname).split('.')
        if (cookieJwt.length === 3) {
            // достаем payload из токена в куках
            let payloadJWT_client = parseJwt(getCookie(JWTname))
            // получаем из jwt данные о роли юзера

            // проверяем, если время жизни токена ушло, данные не отправляем
            if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                return payloadJWT_client['role']
            } else {
                return null
            }
        } else {
            return null
        }
    } else {
        return null
    }
}

export const checkTokenIsExp = () => {
    // получаем данные из локального хранилища и из куков
    if (getCookie(JWTname)) {
        // проверяем валидность токена в куках 
        let cookieJwt = getCookie(JWTname).split('.')
        if (cookieJwt.length === 3) {
            // достаем payload из токена в куках
            let payloadJWT_client = parseJwt(getCookie(JWTname))
            // получаем из jwt данные о роли юзера

            // проверяем, если время жизни токена ушло, данные не отправляем
            if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } else {
        return false
    }
}

export const checkAccData = (func) => {
    // получаем данные из локального хранилища и из куков
    if (getCookie(JWTname) && localStorage.getItem(localStorageName)) {
        // проверяем валидность токена в куках 
        let cookieJwt = getCookie(JWTname).split('.')
        if (cookieJwt.length === 3) {
            // достаем payload из токена в куках
            let payloadJWT_client = parseJwt(getCookie(JWTname))
            // toISOString() - 2024-05-19T14:04:11.012Z
            // +new Date() - 1716127573630
            // если валидный и действующий - устанавливаем данные из локалки
            if (payloadJWT_client['exp'] < Math.floor(new Date().getTime() / 1000)) {
                // НЕ действующий - отправляем юзера на prolongate и обновляем токен
                let dataFromLocal = localStorage.getItem(localStorageName);
                let dataFromCookie = getCookie(JWTname);
                let allData = {'from_local': dataFromLocal,'from_cookie': dataFromCookie};

                return prolongate(allData);
            } else {
                // действующий - ничего не делаем с данными
                //datas = JSON.parse(localStorage.getItem(localStorageName))
                return authorization( JSON.parse(localStorage.getItem(localStorageName)) )
            }
        } else {
            return func
        }
    } else {
        return func
    }
}