import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { getUserRole } from '../../utils/functions';

import Main from '../Main/Main';
import Back from '../Buttons/Back/Back';

import TitleMajor from '../TitleMajor/TitleMajor';
import TitleMinor from '../TitleMinor/TitleMinor';
import ButtonBlueOutlineLarge from '../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';

import {
    //TERMSOFUSE_TITLE,
    //CHOOSE_CATEGORY,
    SOLUTION_NOT_FOUND,
    SOLUTION_INFO,
    BUTTON_SUPPORT,
    PLEASE_LOGIN,
    SUPPORT_ISSUES,
} from './translations';

import styles from './Support.module.css'
import UnavailablePageWrapper from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';

export default class Support extends Component {
    static propTypes = {
        onHelpButtonClick: PropTypes.func.isRequired,

        isLoggedIn: PropTypes.bool.isRequired,

        onShowDialogError: PropTypes.func,
    }

    componentDidMount() {
        document.title = `Центр поддержки сайта TANKIMODS.NET`
        //this.props.onAuthAdmin()
    }

    renderError() {
        /*return this.isErrorState() ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null*/
    }

    renderIssues = () => {
        return SUPPORT_ISSUES.map((item) => {
            return (
                <div className={styles.list_cards_item} key={item.id}>
                    <Link to={item.url} className={styles.category_card}>
                        <div className={styles.category_card_mask}>
                            <div className={styles.category_card_name}>
                                <span className={styles.category_card_text}>{item.category}</span>
                            </div>
                            <div className={styles.category_card_icon_wrapper}>
                                <img alt="" className={styles.category_card_icon} src={item.img} />
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
    }

    handleHelpButtonClick = () => {
        if (this.props.isLoggedIn) {
            this.props.onHelpButtonClick() // this.modId  this.state.modId
        } else {
            this.props.onShowDialogError(PLEASE_LOGIN)
        }
    }

    renderSupportContent = () => {
        return (
            <>
                <div className={styles.support}>
                    <TitleMinor>{SOLUTION_NOT_FOUND}</TitleMinor>
                </div>
                <div className={styles.support_content}>
                <p className={styles.support_text}>{SOLUTION_INFO}</p>
                    <div className={styles.support_control}>
                        <ButtonBlueOutlineLarge
                            onClick={ this.handleHelpButtonClick }
                            gtmTag={'ev_click-help'}
                        >
                        {BUTTON_SUPPORT}
                        </ButtonBlueOutlineLarge>
                    </div>
                </div>
            </>
        )
    }

    renderSupportIcon = () => {
        return <div className={styles.support_panel_icon_wrapper}>
                <img className={styles.support_panel_icon} alt="img" src="//tankimods.net/media/static/headphonesa.svg" />
            </div>
        //  img src wg //static-cspbe-eu.wargaming.net/images/skins/wot/contact.svg?v=3111
    }
    
    render() {
        if ((!window.__URLS__.pages.support) && (getUserRole() !== 'admin')) {
            return <UnavailablePageWrapper />
        } else {
            return (
                <Main>
                    <div className={styles.base}>
                        <div className={styles.head}>
                            <Back />
                            <TitleMajor>Центр поддержки сайта TANKIMODS.NET</TitleMajor>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.inner}>
                                {/* <div className={styles.product_section}>
                                    <TitleMinor>{CHOOSE_CATEGORY}</TitleMinor>
                                    <div className={styles.list_cards}>{this.renderIssues()}</div>
                                </div> */}
                                <div className={styles.product_section}>
                                    <div className={styles.support_panel}>
                                        <div className={styles.support_panel_content}>
                                            {this.renderSupportContent()}
                                        </div>
                                        {this.renderSupportIcon()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Main>
            )
        }
    }
}
