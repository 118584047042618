import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { JWTname } from '../../utils/constants';
import { getCookie, parseJwt } from '../../utils/functions';
import { pushHistoryWithLanding } from '../../utils/routing';

import Main from '../Main/Main';
import Input from '../Input/Input';
import TitleDialog from '../TitleDialog/TitleDialog';
import ButtonBlueOutlineLarge from '../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import EditLabelError from '../Edit/EditLabelError/EditLabelError';

import { 
    //ERROR_PROBLEM_401, 
    ERROR_PROBLEM_ALL, 
    ERROR_PROBLEM_CORRECT, 
    ERROR_PROBLEM_SIGN_IN, 
    FIELD_SIGN_ERROR_TYPES, 
    PASSWORD_RECOVERY, SIGN_IN, 
    TITLE_SIGN_IN
} from './translations';

import styles from './SignIn.module.css';


export default class SignIn extends Component {
    static propTypes = {
        isErrorData: PropTypes.bool,

        onSignIn: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            valueLogin: '',
            valuePass: '',
            descriptionErrorMessage: null,
            isErrorLogin: false,
            isErrorPass: false,
            isErrorProblem: false,
            problemDescription: null,
        }
    }

    componentDidMount() {
        document.title = TITLE_SIGN_IN;
        // if user isAuth = referrer to landing page
        this.checkAuthUser();
    }

    checkAuthUser = () => {
        // получаем данные из локального хранилища и из куков
        if (getCookie(JWTname)) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie(JWTname).split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie(JWTname))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // Math.floor(new Date().getTime() / 1000) - to unix timestamp
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                    // действующий - значит авторизацию не нужна, перенаправляем на галвную
                    pushHistoryWithLanding(this.props.history);
                } // end of IF exp
            }
        }
    }

    handleOnChangeLogin = (valueLogin) => {
        const sliced = valueLogin.slice(0, 50)
        let descriptionErrorMessage = null
        let isErrorLogin = false;

        this.setState({
            isErrorProblem: false,
        })
        
        if (valueLogin.length > 50) {
            isErrorLogin = true;
            descriptionErrorMessage = FIELD_SIGN_ERROR_TYPES.LOGIN_MAX_LIMIT

            this.setState({ descriptionErrorMessage, valueLogin: sliced, isErrorLogin })
        } else {
            this.setState({ descriptionErrorMessage, valueLogin, isErrorLogin })
        }
    }

    handleOnChangePass = (valuePass) => {
        const sliced = valuePass.slice(0, 100)
        let descriptionErrorMessage = null
        let isErrorPass = false;
        //let regexp = /[^А-Яа-я0-9]+/g;
        //let regexp = /[^a-zа-яё,._\-\/=\!\?0-9\s]/gi;
        //valuePass = valuePass.replace(regexp, '');

        this.setState({
            isErrorProblem: false,
        })
        
        if (valuePass.length > 100) {
            isErrorPass = true;
            descriptionErrorMessage = FIELD_SIGN_ERROR_TYPES.PASS_MAX_LIMIT
            this.setState({ descriptionErrorMessage, valuePass: sliced, isErrorPass })
        } else {
            this.setState({ descriptionErrorMessage, valuePass, isErrorPass })
        }
    }

    handleClickSignIn = () => {
        let hasError = false
        if (this.state.valueLogin.length < 1 && this.state.valuePass.length < 1) {
            this.setState({
                isErrorLogin: true,
                isErrorPass: true,
                descriptionErrorMessage: FIELD_SIGN_ERROR_TYPES.FIELDS_EXCEPTED
            })
            hasError = true
        }
        if (this.state.valueLogin.length < 1) {
            this.setState({
                isErrorLogin: true,
                descriptionErrorMessage: FIELD_SIGN_ERROR_TYPES.FIELDS_EXCEPTED
            })
            hasError = true
        }
        if (this.state.valuePass.length < 1) {
            this.setState({
                isErrorPass: true,
                descriptionErrorMessage: FIELD_SIGN_ERROR_TYPES.FIELDS_EXCEPTED
            })
            hasError = true
        }
        
        if (hasError) {
            return
        }

        //const data = { ...this.state, userId: getUserId() }
        this.props.onSignIn(this.state.valueLogin, this.state.valuePass)
    }

    renderErrorBlock() {
        return this.props.isErrorData===null ? (
                <span id="jsc-error-message-block-9cea-e336-" className="jsc-error-message-block">
                    <div className={styles.form_errors__block}>
                        <div className={styles.msg_error__content}>
                            <p className={styles.form_errors__content}>
                                {ERROR_PROBLEM_ALL}
                            </p>
                        </div>
                    </div>
                </span>
            ) : this.props.isErrorData ? (
                <span id="jsc-error-message-block-9cea-e336-" className="jsc-error-message-block">
                    <div className={styles.form_errors__block}>
                        <div className={styles.msg_error__content}>
                            <p className={styles.form_errors__content}>
                                {ERROR_PROBLEM_CORRECT}
                            </p>
                        </div>
                    </div>
                </span>
            ) : this.state.isErrorProblem ? (
                <span id="jsc-error-message-block-9cea-e336-" className="jsc-error-message-block">
                    <div className={styles.form_errors__block}>
                        <div className={styles.msg_error__content}>
                            <p className={styles.form_errors__content}>
                                {ERROR_PROBLEM_CORRECT}
                            </p>
                        </div>
                    </div>
                </span>
            ) : null
    }

    renderErrorMessage() {
        return this.state.descriptionErrorMessage ? (
            <div className={styles.fileError}>
                <EditLabelError message={this.state.descriptionErrorMessage} />
            </div>
        ) : null
    }

    renderSignForm() {
        return (
            <div id="js-sign-in">
                <TitleDialog>{SIGN_IN}</TitleDialog>
                {this.renderErrorBlock()}
                <div className={styles.form}>
                    <div className={styles.input}>
                    <Input
                        key='email'
                        placeholder="Логин"
                        value={this.state.valueLogin}
                        type=""
                        isError={this.state.isErrorLogin}
                        //isFocusOn={false}
                        onChange={this.handleOnChangeLogin}
                        //onSubmit={()=>{}}
                    />
                    </div>
                    <div className={styles.input}>
                    <Input
                        key='pass'
                        placeholder="Пароль"
                        value={this.state.valuePass}
                        type=""
                        isError={this.state.isErrorPass}
                        //isFocusOn={false}
                        onChange={this.handleOnChangePass}
                        //onSubmit={()=>{}}
                    />
                    </div>

                    <div className={styles.button_send}>
                        <ButtonBlueOutlineLarge onClick={this.handleClickSignIn}>
                            <span className={styles.button_sign}>{SIGN_IN}</span>
                        </ButtonBlueOutlineLarge>
                    </div>

                    <hr className={styles.hr} />
                    <p className={styles.attention}>
                        {ERROR_PROBLEM_SIGN_IN} 
                        <Link className={styles.sign_info_link} to="https://tankimods.net/personal/password_reset/" target='_blank'>{PASSWORD_RECOVERY}</Link>
                    </p>
                    {this.renderErrorMessage()}
                </div>
            </div>
        )
    }
    
    render() {
        return (
            <Main>
                <div className={styles.base}>
                    {this.renderSignForm()}
                </div>
            </Main>
        )
    }
}
