import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { JWTname } from '../../utils/constants';
import { getCookie, parseJwt } from '../../utils/functions';
import { pushHistoryWithLanding } from '../../utils/routing';

import Main from '../Main/Main';
import Input from '../Input/Input';
import TitleDialog from '../TitleDialog/TitleDialog';
import ButtonBlueOutlineLarge from '../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import EditLabelError from '../Edit/EditLabelError/EditLabelError';

import { 
    ERROR_PROBLEM_401, 
    ERROR_PROBLEM_ALL, 
    ERROR_PROBLEM_CORRECT,
    FIELD_SIGNUP_ERROR_TYPES,
    SIGN_UP,
    SIGNUP_BUTTON_OK,
    SIGNUP_BUTTON_TO_LANDING,
    TITLE_SIGNUP, 
} from './translations';

import styles from './SignUp.module.css';


export default class SignUp extends Component {
    static propTypes = {
        isErrorData: PropTypes.bool,
        accessKey: PropTypes.bool,
        accessData: PropTypes.shape({
            accessId: PropTypes.number,
            accessName: PropTypes.string,
        }),

        onSignUp: PropTypes.func,
        onKeySignUp: PropTypes.func,
    }

    constructor() {
        super()

        this.state = {
            valueLogin: '',
            valuePass1: '',
            valuePass2: '',
            descriptionErrorMessage: null,
            isErrorLogin: false,
            isErrorPass: false,
            isErrorPass2: false,
            isErrorProblem: false,
            problemDescription: null,

            key: null,
        }
    }

    componentDidMount() {
        document.title = TITLE_SIGNUP;
        // if user isAuth = referrer to landing page
        this.checkAuthUser();
        // if authKey url denied - referrer to landing page
        this.checkKeyId();
    }

    checkAuthUser = () => {
        // получаем данные из локального хранилища и из куков
        if (getCookie(JWTname)) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie(JWTname).split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie(JWTname))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // Math.floor(new Date().getTime() / 1000) - to unix timestamp
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                    // действующий - значит авторизацию не нужна, перенаправляем на галвную
                    pushHistoryWithLanding(this.props.history);
                } // end of IF exp
            }
        }
    }

    checkKeyId = () => {
        // ?next=%2Fid%2Fopenid%2F389482569099697139%2F    ?next=/id/openid/389482569099697139/
        // ?key=%2Fid%2F29586346356547467%2F
        // /redirect/?key=p7Cf6YbO3jJR9xxHx8qkbgFas0ghdkna
        if (document.location.search.includes('reg', 0) && 
            document.location.search.includes('?key=%2Freg%2F', 0) && 
            (document.location.search.slice(14) !== '')) {
            this.setState({
                key: document.location.search.slice(14).replace('%2F', ''),
            })

            this.props.onKeySignUp(document.location.search.slice(14).replace('%2F', ''));
        } else {
            pushHistoryWithLanding(this.props.history);
        }
    }

    handleOnChangeLogin = (valueLogin) => {
        const sliced = valueLogin.slice(0, 50)
        let descriptionErrorMessage = null
        let isErrorLogin = false;

        this.setState({
            isErrorProblem: false,
        })
        
        if (valueLogin.length > 50) {
            isErrorLogin = true;
            descriptionErrorMessage = FIELD_SIGNUP_ERROR_TYPES.LOGIN_MAX_LIMIT 
            this.setState({ descriptionErrorMessage, valueLogin: sliced, isErrorLogin })
        } else {
            this.setState({ descriptionErrorMessage, valueLogin, isErrorLogin })
        }
    }

    handleOnChangePass1 = (valuePass1) => {
        const sliced = valuePass1.slice(0, 100)
        let descriptionErrorMessage = null
        let isErrorPass = false;

        this.setState({
            isErrorProblem: false,
        })
        
        if (valuePass1.length > 100) {
            isErrorPass = true;
            descriptionErrorMessage = FIELD_SIGNUP_ERROR_TYPES.PASS_MAX_LIMIT 
            this.setState({ descriptionErrorMessage, valuePass1: sliced, isErrorPass })
        } else {
            this.setState({ descriptionErrorMessage, valuePass1, isErrorPass })
        }
    }

    handleOnChangePass2 = (valuePass2) => {
        const sliced = valuePass2.slice(0, 100)
        let descriptionErrorMessage = null
        let isErrorPass = false;

        this.setState({
            isErrorProblem: false,
        })

        if (valuePass2 !== this.state.valuePass1) {
            isErrorPass = true;
            //descriptionErrorMessage = FIELD_SIGNUP_ERROR_TYPES.FIELDS_PASS_INCORRECT 
            this.setState({ isErrorPass })
        }
        
        if (valuePass2.length > 100) {
            isErrorPass = true;
            descriptionErrorMessage = FIELD_SIGNUP_ERROR_TYPES.PASS_MAX_LIMIT 
            this.setState({ descriptionErrorMessage, valuePass2: sliced, isErrorPass })
        } else {
            this.setState({ descriptionErrorMessage, valuePass2, isErrorPass })
        }
    }

    handleClickSignUp = () => {
        let hasError = false
        if (this.props.isErrorData) {
            hasError = true
        } else if (this.state.valueLogin.length < 1 && this.state.valuePass1.length < 1) {
            this.setState({
                isErrorLogin: true,
                isErrorPass: true,
                descriptionErrorMessage: FIELD_SIGNUP_ERROR_TYPES.FIELDS_EXCEPTED
            })
            hasError = true
        }
        if (this.state.valueLogin.length < 1 && !this.props.isErrorData) {
            this.setState({
                isErrorLogin: true,
                descriptionErrorMessage: FIELD_SIGNUP_ERROR_TYPES.FIELDS_EXCEPTED_LOGIN
            })
            hasError = true
        }
        if ((this.state.valuePass1.length < 1 && !this.props.isErrorData) 
            || (this.state.valuePass2.length < 1 && !this.props.isErrorData)) {
            this.setState({
                isErrorPass: true,
                descriptionErrorMessage: FIELD_SIGNUP_ERROR_TYPES.FIELDS_EXCEPTED_PASS
            })
            hasError = true
        }
        if (this.state.valuePass1 !== this.state.valuePass2) {
            this.setState({
                isErrorPass: true,
                descriptionErrorMessage: FIELD_SIGNUP_ERROR_TYPES.FIELDS_PASS_INCORRECT
            })
            hasError = true
        }
        
        if (hasError) {
            return
        }

        this.props.onSignUp(this.state.valueLogin, this.state.valuePass1, this.props.accessData.accessName, this.state.key)
    }

    renderErrorBlock() {
        return this.props.isErrorData===null 
            || (this.props.isErrorData || this.state.isErrorProblem) ? (
            <span id="jsc-error-message-block-9cea-e336-" className="jsc-error-message-block">
                <div className={styles.form_errors__block}>
                    <div className={styles.msg_error__content}>
                        <p className={styles.form_errors__content}>
                            {this.props.isErrorData===null ? 
                                ERROR_PROBLEM_ALL : (
                                (this.props.isErrorData || this.state.isErrorProblem) ?
                                    ERROR_PROBLEM_401 : ERROR_PROBLEM_CORRECT
                            )}
                        </p>
                    </div>
                </div>
            </span>
        ) : null
    }

    renderErrorMessage() {
        return this.state.descriptionErrorMessage ? (
            <>
            {/* <hr className={styles.hr} /> */}
            <div className={styles.fileError}>
                <EditLabelError message={this.state.descriptionErrorMessage} />
            </div>
            </>
        ) : null
    }

    renderButton() {
        return !this.props.isErrorData ? (
            <div className={styles.button_send}>
                <ButtonBlueOutlineLarge onClick={this.handleClickSignUp}>
                    <span className={styles.button_sign}>{SIGNUP_BUTTON_OK}</span>
                </ButtonBlueOutlineLarge>
            </div>
        ) : (
            <div className={styles.button_send}>
                <ButtonBlueLarge onClick={() => pushHistoryWithLanding(this.props.history)}>
                    {SIGNUP_BUTTON_TO_LANDING}
                </ButtonBlueLarge>
            </div>
        )
    }

    renderSignForm() {
        return (
            <div id="js-sign-up">
                <div className={styles.header}>
                    <TitleDialog>{SIGN_UP}</TitleDialog>
                    <div className={styles.header_left}>
                        <p className={styles.header_left__text} title={this.props.accessData.accessName ? `Аккаунт на сайте` : null}>
                            {this.props.accessData.accessName ? this.props.accessData.accessName : null}
                        </p>
                    </div>
                </div>

                {this.renderErrorBlock()}

                <div className={styles.form}>
                    <div className={styles.input}>
                    <Input
                        key='email'
                        isDisabled={this.props.isErrorData}
                        placeholder="Придумайте логин"
                        value={this.state.valueLogin}
                        type=""
                        isError={this.state.isErrorLogin}
                        //isFocusOn={false}
                        onChange={this.handleOnChangeLogin}
                        //onSubmit={()=>{}}
                    />
                    </div>
                    <div className={styles.input}>
                    <Input
                        key='pass'
                        isDisabled={this.props.isErrorData}
                        placeholder="Придумайте пароль"
                        value={this.state.valuePass1}
                        type=""
                        isError={this.state.isErrorPass}
                        isPassword={true}
                        //isFocusOn={false}
                        onChange={this.handleOnChangePass1}
                        //onSubmit={()=>{}}
                    />
                    </div>
                    <div className={styles.input}>
                    <Input
                        key='pass'
                        isDisabled={this.props.isErrorData}
                        placeholder="Повторите пароль"
                        value={this.state.valuePass2}
                        type=""
                        isError={this.state.isErrorPass}
                        isPassword={true}
                        //isFocusOn={false}
                        onChange={this.handleOnChangePass2}
                        //onSubmit={()=>{}}
                    />
                    </div>

                    {this.renderButton()}

                    {this.renderErrorMessage()}
                </div>
            </div>
        )
    }
    
    render() {
        return (
            <Main>
                <div className={styles.base}>
                    {this.renderSignForm()}
                </div>
            </Main>
        )
    }
}
