import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ButtonBlueOutlineSmall.module.css';

export default class ButtonBlueOutlineSmall extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        glitch: PropTypes.bool,
        glyph: PropTypes.oneOf(['plus']).isRequired,
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles[`base__${this.props.glyph}`]]: this.props.glyph,
            [styles.glitch]: this.props.glitch,
        })

        return (
            <button className={classNameBase} onClick={this.props.onClick}>
                <span className={styles.content}>{this.props.children}</span>
            </button>
        )
    }
}
