import React from "react";

export const JWTname = 'tm_jwt';
export const localStorageName = 'tm:user';

export const BREAKPOINT = 1366

export const expresNumb = /[^\d]/gi; // D (удаляет все символы и буквы), d (удаляет цифры),заменяем буквы(\\w) и цифры(\\d)
export const expresAlf = /[^\w]/gi; // удаляет все спецсимволы / [^\d] - оставляет только цифры

export const expressionsAll = [
    {numbers: /[^\d]/gi, // numbers
    specials: /[^\w]/gi} // specials
]

export const allTags = [ 
    {id: 10, caption: "Модпаки"},
    {id: 11, caption: "Ангары"},
    {id: 12, caption: "Прицелы"},
    {id: 13, caption: "Шкурки"},
    {id: 14, caption: "Озвучки"},
    {id: 15, caption: "Интерфейс"},
    {id: 16, caption: "Другое"},
    {id: 17, caption: "Инструменты"} 
];
export const allVersions = [ 
    {id: 177, version: "1.25.0.0"},
    {id: 176, version: "1.24.0.0"},
    {id: 175, version: "1.23.1.0"},
    {id: "all", version: "Все версии"}
];

export const SEARCH_RESULT_EMPTY = "По вашему запросу ничего не найдено. Попробуйте изменить запрос.";

export const SEARCH_RESULT_EMPTY_BY_OWNER = "Моды от этого пользователя не найдены.";

export const messages = (title,ownerName,tag) => {
    return [
      {key: 0, filterByTitle: <React.Fragment>Результаты поиска по запросу {title} — Моды для Мир танков</React.Fragment>},
      {key: 1, filterByOwner: <React.Fragment>Скачать моды от {ownerName} для Мир танков</React.Fragment>},
      {key: 2, filterByTag: <React.Fragment>Скачать моды по тегу {tag} — Моды для Мир танков</React.Fragment>},
      {key: 3, filterNull: <React.Fragment>Моды для Мир танков. Скачай и пользуйся!</React.Fragment>}
    ]
  }
  export const SEARCH_RESULT_PAGE_TITLE_BY_TITLE = (searchTerm, count) => {
    return <React.Fragment>По запросу {searchTerm} найдено: {count}</React.Fragment>
  }
  export const SEARCH_RESULT_PAGE_TITLE_BY_UNKNOWN = () => {
    return <React.Fragment>По Вашему запросу ничего не найдено</React.Fragment>
  }
  export const SEARCH_RESULT_PAGE_TITLE_BY_OWNER = (ownerName,count) => {
    return <React.Fragment>Моды от {ownerName}: {count}</React.Fragment>
  };

export const SEARCH_RESULT_FILTER_TYPES = {
    OWNER: 'owner',
    TAGS: 'tags',
    TITLE: 'title',
}

export const SEARCH_RESULT_LANGUAGES = {
    ALL: 'all',
    EN: 'en',
    RU: 'ru',
}

export const getGalleryItemsCountPerRow = () => {
    return window.innerWidth > BREAKPOINT ? 4 : 3
}
export const GALLERY_ITEMS_COUNT_PER_ROW = getGalleryItemsCountPerRow()

export const SEARCH_RESULT_ITEMS_PER_PAGE = 3 * GALLERY_ITEMS_COUNT_PER_ROW

export const SEARCH_RESULT_ITEMS_ORDERING = {
    RATING: '-rating',
    CHANGED_AT: '-changed_at',
}

export const MOD_STATE = {
    DEFAULT: -1,
    DRAFT: 0,
    IN_REVIEW: 1,
    REJECTED: 2,
    PUBLISHED: 3,
    PUBLISHED_UPDATE_IN_REVIEW: 4,
    PUBLISHED_UPDATE_REJECTED: 5,
    HIDDEN: 6,
}

export const MOD_VERSION_STATUS = {
    PENDING_REVIEW: 0,
    PUBLISHED: 1,
    REJECTED: 2,
}

export const MOD_STATUS_TYPES = {
    DRAFT: 0,
    PENDING_REVIEW: 1,
    PUBLISHED: 2,
    REJECTED: 3,
}

export const ADMIN_MOD_STATUS_TYPES = {
    DEFAULT: -1,
    DRAFT: 0,
    PENDING_REVIEW: 1,
    PUBLISHED: 2,
    REJECTED: 3,
}

export const MOD_VERSION_COMMENT_TYPES = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
}

export const MOD_STATE_MAPPING = {
    [MOD_STATE.DRAFT]: 'draft',
    [MOD_STATE.IN_REVIEW]: 'review',
    [MOD_STATE.REJECTED]: 'rejected',
    [MOD_STATE.PUBLISHED]: 'published',
    [MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW]: 'published_update_in_review',
    [MOD_STATE.PUBLISHED_UPDATE_REJECTED]: 'published_update_rejected',
    [MOD_STATE.HIDDEN]: 'hidden',
}

export const MOD_VERSION_STATUS_MAPPING = {
    [MOD_VERSION_STATUS.PENDING_REVIEW]: 'review',
    [MOD_VERSION_STATUS.PUBLISHED]: 'published',
    [MOD_VERSION_STATUS.REJECTED]: 'rejected',
}

export const HTML_MEDIA_ITEM_LENGHT = 30

export const MAX_SEARCH_LENGTH = 255
export const MAX_AUTHOR_LENGTH = 255
export const MAX_CHANGELOG_LENGTH = 1000
export const MAX_COMMENT_LENGTH = 1000
export const MAX_COMMENTS_LENGTH = 1000
export const MAX_DESCRIPTION_LENGTH = 3000
export const MAX_INSTALLATION_GUIDE_LENGTH = 1000
export const MAX_MOD_VERSION_LENGTH = 15
export const MAX_TITLE_LENGTH = 255

export const MIN_DESCRIPTION_LENGTH = 1
export const MIN_INSTALLATION_GUIDE_LENGTH = 1
export const MIN_MOD_VERSION_LENGTH = 1
export const MIN_TITLE_LENGTH = 3

export const FIELD_VALIDATION_ERROR_TYPES = {
    COMMON_ERROR: 'common_error',
    EMPTY_VALUES: 'empty_values',
    FILE_SIZE_ERROR: 'file_size_error',
    FILE_TYPE_ERROR: 'file_type_error',
    MAX_LIMIT: 'max_limit',
    MIN_LIMIT: 'min_limit',
    NO_FILE_SELECTED: 'no_file_selected',
    NOT_SUPPORTED_IMAGE_DIMENSIONS: 'not_supported_image_dimensions',
}

export const CHANGELOG_FIRST_PAGE_MAX_ITEMS = 5
export const COMMENTS_FIRST_PAGE_MAX_ITEMS = 5

export const SCROLL_TO_COMPONENTS_PARAMETERS = { align: 'top', duration: 500, offset: -100 }

export const REPORT_MOD_PROBLEM_TYPES = {
    TECHNICAL_ISSUE: 0,
    UNACCEPTABLE_CONTENT: 1,
    CHEAT: 2,
    COPYRIGHT: 3,
}

export const REPORT_MOD_DESCRIPTION_MAX_LENGTH = 4000

export const HELP_PROBLEM_TYPES = {
    TECHNICAL_ISSUE: 0,
    URL: 1,
}

export const HELP_DESCRIPTION_MAX_LENGTH = 4000

export const NEWS_ITEMS_LIMIT = 10


export const ERROR_USER_NOT_FOUND = (error_msg) => {
 return <React.Fragment>Такой пользователь не найден. <br />Возможно это ошибка сервера, а возможно пользователь и в правду отсутствует в базе портала. <br />Для получения точного ответа обратитесь к системному администратору. <br /><br />Код ошибки: {error_msg}</React.Fragment>
} 