import React from 'react';

import { urls } from '../../utils/routing';
import { JWTname, localStorageName } from '../../utils/constants';
//import { pushHistoryWithErrorNotFound } from '../../utils/routing';

import Main from '../Main/Main';

import styles from './Prolongate.module.css';


export default function Prolongate(props) {
    // Функция setCookie() поможет нам сохранить JWT в файле cookie
    function setCookie(name, value, options = {}) {
        options = {
            path: '/',
            // при необходимости добавить другие значения по умолчанию
            ...options
        };
        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    }
    
    function getParameters() {
        //let PageLoaded = false;

        window.onload = function() {
            //PageLoaded = true;
            // задаем переменные используемые в коде дальше
            let err = []; // массив вывода информации в консоль

            // проверяем что params не пустой и не undefined
            if (typeof window.location.search === 'undefined' || window.location.search === null || window.location.search === "") {
                err = {status: "error", context: {msg: "INVALID_GET_ITEMS", code: 404}};
                window.location = urls.errorNotFound;
            } else {
                // получаем строку с гет параметрами из юрл
                var params = window.location.search
                .replace('?','')
                .split('&')
                .reduce(
                    function(p,e){
                        var a = e.split('=');
                        p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                        return p;
                    },
                    {}
                );
                //console.log(params['account_id']);

                let rawResponseData = {};

                // проверка валидности токена
                const jwtParts = params['access_token'].split('.'); // jwtParts - теперь как массив с объектами
                // const decodedHeader = JSON.parse(atob(jwtParts[0])); // Декодируем заголовок
                if ( jwtParts.length !== 3 ) {
                    err = {status: "error", context: {msg: "INVALID_TOKEN", code: 407}};
                    window.location = urls.errorNotFound;
                } else {
                    // проверяем а вдруг запрос пустой
                    if ( (typeof params['spa_id'] !== 'undefined') && (typeof params['spa_username'] !== 'undefined') && (typeof params['access_token'] !== 'undefined') && (typeof params['expires'] !== 'undefined') ) {
                        // устанавливаем в куки
                        // ... \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
                        // берем время в unix и переводим в дату
                        const expToDate = new Date(params['expires']*1000);
                        // expToDate - заменим, чтобы хранился дольше
                        //setCookie('mtm_jwt', params['access_token'], {expires: expToDate, path: '/', domain: ".tankimods.net", secure: true, samesite: 'strict'});
                        setCookie(JWTname, params['access_token'], {expires: expToDate, path: '/', secure: true, samesite: 'strict'}); // потом добавить domain
                        // устанавливаем данные в локальное хранилище
                        //let valueStorage = JSON.stringify({spa_id: params['account_id'], nickname: params['spa_username'], expires: expires_at.toUTCString()});
                        let valueStorage = JSON.stringify({spaId: parseInt(params['spa_id']), spaUsername: params['spa_username'], expires: expToDate});
                        localStorage.setItem(localStorageName, valueStorage);
                        //localStorage.removeItem("id:nick");
                        // ... \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
                        // передаем полученные данные в переменную для отправки в state
                        rawResponseData = {
                            spaId: parseInt(params['spa_id']),
                            spaUsername: params['spa_username'],
                            accessToken: params['access_token'],
                            expiresAt: parseInt(params['expires'])
                        };
                        //console.log(rawResponseData);
                        // отправим информацию в консоль
                        err = {status: "ok", context: {msg: "SET_ITEMS", code: 200}};
                    } else {
                        err = {status: "error", context: {msg: "INVALID_ITEMS", code: 407}};
                        window.location = urls.errorNotFound;
                        //console.log(JSON.stringify(err));
                    }
                }
                console.log(err); // выводим информацию о выполнении кода в консоль
                // передаем полученные данные в функцию, которая занесет их в state
                props.authorization(rawResponseData);
                // перенаправляем юзера на сайт
                let url_ =  document.referrer; // 'http://localhost:3000/' // document.referrer
                let ref_ = url_.match(/:\/\/(.[^/]+)/)[1];
                //console.log('http://'+ref_+'/'); // получаем только домен
                window.location = 'http://'+ref_+'/'; // this.props.history.goBack()
            }
        }
    }

    function renderProlongatePage(props) {
        /*if ( document.referrer.indexOf(window.location.origin) !== -1 ) {
            return <div>Prolongate</div>
        } else {
            return pushHistoryWithErrorNotFound(props.history); // window.location = "https://justsherlock.ru/err404.html"; // если адрес введен вручную или переход с другой страницы, выход на ошибку
        }*/
        
        return (
            <Main>
                {/* <div className={styles.base}>
                    <TitleDialog>Авторизация</TitleDialog>
                    {getParameters()}
                </div> */}
                <div className={styles.body}>
                    <div className={styles.block}>
                        <div className={styles.title}>
                            <span>Авторизация...</span>
                        </div>
                    </div>
                    {getParameters()}
                </div>
            </Main>
        )
    }

    return (
        renderProlongatePage(props)
    )
}
