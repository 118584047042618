import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { each } from 'lodash';

import { urls } from '../../utils/routing';
import { getUsername, getUserRole } from '../../utils/functions';

import Main from '../../components/Main/Main';
import Back from '../../components/Buttons/Back/Back';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import Information from '../../components/Information/Information';
import ErrorEmptySubscriptions from '../../components/ErrorEmptySubscriptions/ErrorEmptySubscriptions';
import FollowedItem from '../../components/FollowedItem/FollowedItem';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';
import Error from '../../components/Error/Error';
import Divider from '../../components/Divider/Divider';
import TitleMinor from '../../components/TitleMinor/TitleMinor';

import { COMMON_ERROR, TO_LANDING } from '../../translations';

import {
    SUBSCRIPTIONS_PAGE_TITLE,
    SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER,
    SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE,
    SUBSCRIPTIONS_PAGE_LOGIN,
    SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE,
    SUBSCRIPTIONS_PAGE_FAVORITES_TITLE
} from './translations';

import styles from './Followed.module.css';

export default class Followed extends Component {
    static propTypes = {
        isError: PropTypes.bool,
        isFetching: PropTypes.bool.isRequired,
        isLoggedIn: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            isUpdated: PropTypes.bool.isRequired,
            mod: PropTypes.shape({
                cover: PropTypes.string,
                id: PropTypes.number.isRequired,
                mark: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                versions: PropTypes.arrayOf(PropTypes.shape({
                    createdAt: PropTypes.string.isRequired,
                    downloadUrl: PropTypes.string.isRequired,
                    id: PropTypes.number.isRequired,
                    modVersion: PropTypes.string.isRequired,
                    versionFileOriginalName: PropTypes.string.isRequired,
                    versionFileSize: PropTypes.number,
                })).isRequired,
            }),
        })),

        lang: PropTypes.string.isRequired,

        onIncrementDownloadCount: PropTypes.func.isRequired,
        onIgnoreUpdate: PropTypes.func.isRequired,
        onShowLoginDialog: PropTypes.func.isRequired,
        fetchItems: PropTypes.func.isRequired,
        onUnsubscribe: PropTypes.func.isRequired,
    }

    componentDidMount() {
        if (getUsername().spaId !== null || getUsername().spaUsername !== null) {
            this.props.fetchItems(getUsername())
        }
        /*if (checkTokenIsExp() === true) {
            this.props.fetchItems(getUsername())
        }*/
        document.title = `Мои подписки. TANKIMODS.NET - моды для Мир танков`
    }

    renderBody() {
        if (this.props.isError || this.props.items.length === 0 || !this.props.isLoggedIn) {
            return
        }
        let updated = []
        let other = []
        each(this.props.items, (item) => {
            if (item.isUpdated) {
                updated.push(item)
            } else {
                other.push(item)
            }
        })
        return (
            <div>
                {this.renderItems(updated, true)}
                {other.length && updated.length ? (
                    <div className={styles.divider}><Divider /></div>
                ) : null}
                {this.renderItems(other, false)}
            </div>
        )
    }

    renderItems(items, isUpdated) {
        const title = isUpdated ?
            SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE(items.length) :
            SUBSCRIPTIONS_PAGE_FAVORITES_TITLE(items.length)

        return items.length ? (
            <React.Fragment>
                <div className={styles.itemsHeader}>
                    <TitleMinor>{title}</TitleMinor>
                    {/* {this.renderDownloadAllButton(items, isUpdated)} */}
                </div>
                {items.map((item) => {
                    return (
                        <FollowedItem
                            isNew={isUpdated}
                            isUpdated={isUpdated}
                            mod={item.mod}
                            key={item.id}
                            id={item.id}
                            fetchItems={this.props.fetchItems}
                            onIncrementDownloadCount={this.props.onIncrementDownloadCount} 
                            onIgnoreUpdate={this.props.onIgnoreUpdate}
                            onUnsubscribe={this.props.onUnsubscribe} 
                        />
                    )
                })}
            </React.Fragment>
        ) : null
    }
    
    renderTitle() {
        const countLabel = this.props.isError ? '-' : this.props.items.length
        return this.props.isLoggedIn ? SUBSCRIPTIONS_PAGE_TITLE(countLabel) : SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER()
    }
    
    renderInformation() {
        return !this.props.isLoggedIn && !this.props.isError ? (
            <Information
                message={SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE}
                buttonCaption={SUBSCRIPTIONS_PAGE_LOGIN}
                onButtonClick={this.props.onShowLoginDialog}
            />
        ) : null
    }

    renderEmptyList = () => {
        return this.props.isLoggedIn && !this.props.isError && !this.props.isFetching && (this.props.items.length === 0) ? (
            <ErrorEmptySubscriptions history={this.props.history} />
        ) : null
    }

    renderError() {
        return this.props.isError ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null
    }

    render() {
        if ((!window.__URLS__.pages.subscriptions) && (getUserRole() !== 'admin')) {
            return <UnavailablePage />
        } else {
            const classNameTitle = classNames(styles.title, {
                [styles.isMuted]: this.props.isError || this.props.items.length === 0,
            })
            return (
            <Main>
                <div className={styles.base}>
                    <div className={styles.head}>
                        <Back caption={TO_LANDING} to={urls.landing} />
                        <TitleMajor>
                            <span className={classNameTitle}>{this.renderTitle()}</span>
                        </TitleMajor>
                    </div>

                    <div className={styles.body}>
                        {this.renderInformation()}
                        {this.renderEmptyList()}
                        {this.renderError()}
                        {this.renderBody()}
                    </div>
                </div>
            </Main>
            )
        }
    }
}
